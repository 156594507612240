import { createSlice } from '@reduxjs/toolkit';
import mcli from '../../libs/matrix';
const initialState = {
    authorization: {},
    name: '',
    imageUrl: ''
};
export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        updateMatrixProfile: (_state) => {
            const me = mcli.getUser(mcli.getUserId() || "");
            if (me) {
                return {
                    name: me.displayName || "",
                    imageUrl: mcli.mxcUrlToHttp(me.avatarUrl || "") || "",
                    authorization: {
                        token: mcli.getAccessToken()
                    }
                };
            }
        },
        clear: () => {
            return {
                authorization: {},
                name: '',
                imageUrl: ''
            };
        },
    },
});
export const { updateMatrixProfile, clear } = userSlice.actions;
export const selectAuthorization = (state) => state.user.authorization;
export const selectName = (state) => state.user.name;
export const selectImageUrl = (state) => state.user.imageUrl;
export default userSlice.reducer;
