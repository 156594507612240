import * as React from 'react';
import TenorFetch from 'tenor-fetch';
const GifPicker = ({ onClick }) => {
    const apiKey = process.env.TENOR_API_KEY || '';
    const tf = new TenorFetch(apiKey);
    const [previewId, setPreviewId] = React.useState(null);
    const [list, setList] = React.useState([]);
    React.useEffect(() => {
        tf.trending().then((data) => {
            setList(data.results);
        });
    }, []);
    const search = (term) => {
        tf.search(term).then((data) => {
            setList(data.results);
        });
    };
    if (!list)
        return React.createElement(React.Fragment, null);
    return (React.createElement("div", { className: "flex flex-col flex-nowrap overflow-y-auto scrollbar h-full p-1" },
        React.createElement("div", { className: "flex flex-row w-full border-t bg-gray-150 dark:bg-neutral-800 rounded-sm p-3" },
            React.createElement("input", { type: "text", className: "w-full p-2", onChange: (e) => search(e.target.value), placeholder: "powered by tenor..." })),
        React.createElement("div", { className: "scrollbar overflow-y-auto grid grid-cols-6 p-1" }, list.length > 0 &&
            list.map((gif) => {
                return (React.createElement("div", { key: gif.id, onMouseEnter: () => {
                        setPreviewId(gif.id);
                    }, onMouseLeave: () => setPreviewId(null), className: "flex items-center justify-center border border-transparent hover:border-primary-full rounded p-1" },
                    React.createElement("img", { className: "cursor-pointer h-[120px]", onClick: () => onClick(gif.media[0].tinygif.url), src: previewId === gif.id
                            ? gif.media[0].gif.url
                            : gif.media[0].gif.preview })));
            }))));
};
export default GifPicker;
