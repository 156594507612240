import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import { getLastMessage, parseMessage } from '../../libs/matrix/message';
import { toggleChatLeaveRoom } from '../../reducer/app';
import { selectRoom } from '../../reducer/matrix';
import ChatPresence from '../ChatPresence';
import Bell from '../Icons/Bell';
import BellSlash from '../Icons/BellSlash';
import EllipsisVertical from '../Icons/EllipsisVertical';
import Pin from '../Icons/Pin';
import ShieldCheck from '../Icons/ShieldCheck';
import Trash from '../Icons/Trash';
import { Tooltip } from 'react-tooltip';
const ListItem = ({ roomId, onSelect, isSelected, isPinned, onPin, hideOptions, }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const room = useAppSelector((state) => selectRoom(state, roomId));
    const [isOpen, setIsOpen] = React.useState(false);
    const [isRoomMuted, setIsRoomMuted] = React.useState(false);
    const menuRef = React.useRef(null);
    React.useEffect(() => {
        if (!room)
            return;
        const checkRules = setTimeout(() => {
            const pushRule = mcli.getRoomPushRule('global', roomId);
            const dontNotify = pushRule?.actions[0] === 'dont_notify';
            setIsRoomMuted(dontNotify);
        }, 100);
        document.addEventListener('mousedown', closeMenu);
        return () => {
            document.removeEventListener('mousedown', closeMenu);
            clearTimeout(checkRules);
        };
    }, [room, room?.lastEventId]);
    const closeMenu = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };
    const muteRoom = (roomId) => {
        if (!isRoomMuted) {
            setIsRoomMuted(true);
            mcli?.setRoomMutePushRule('global', roomId, true);
        }
        else {
            setIsRoomMuted(false);
            mcli?.setRoomMutePushRule('global', roomId, false);
        }
    };
    const openMenu = (e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
    };
    if (!room)
        return React.createElement("li", null);
    let avatar = room.imageUrl;
    if (!avatar && room.members.length === 2) {
        room.members.forEach((member) => {
            if (member.userId !== mcli.getUserId()) {
                avatar = member.imageUrl;
            }
        });
    }
    const lastEvent = getLastMessage(room.events);
    const mimetype = lastEvent?.content?.info?.mimetype;
    const splittedMimeType = mimetype?.split('/') || [];
    let mediaType = '';
    if (splittedMimeType.length === 2) {
        if (splittedMimeType[0] === 'image')
            mediaType = t('image');
        if (splittedMimeType[0] === 'video')
            mediaType = t('video');
        if (splittedMimeType[0] === 'audio')
            mediaType = t('audio');
        if (splittedMimeType[1] === 'gif')
            mediaType = t('gif');
    }
    const otherMember = room.members.find((member) => member.userId !== mcli.getUserId());
    return (React.createElement(React.Fragment, null,
        React.createElement("li", { onClick: () => onSelect(roomId) },
            React.createElement("div", { className: `${isSelected && 'listitem-selected'} ListItem group` },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("div", { className: "mr-3" },
                        avatar ? (React.createElement("div", { className: "rounded-full bg-slate-400 w-8" },
                            React.createElement("img", { src: avatar, alt: "room avatar", className: "w-8 h-8" }))) : (React.createElement("div", { className: "rounded-full bg-primary-full w-8 h-8 flex items-center justify-center text-white" }, room.name.substring(0, 1))),
                        room.isDirect && (React.createElement(ChatPresence, { userId: otherMember?.userId || '', presence: otherMember?.presence || '', isSelected: isSelected }))),
                    React.createElement("div", { className: "flex flex-col justify-start w-full" },
                        React.createElement("div", { className: "flex items-center justify-between text-[#001A34]" },
                            React.createElement("div", { className: "flex overflow-hidden max-w-[17rem]" },
                                React.createElement("div", { className: "truncate max-w-[17rem]", "data-tooltip-id": `${room.name.length >= 26 ? 'name-tooltip' : undefined}`, "data-tooltip-content": room.name }, room.name || ''),
                                React.createElement(Tooltip, { id: "name-tooltip", offset: 10, className: "w-60" }),
                                React.createElement("div", { className: "flex items-center" },
                                    room.isEncrypted && (React.createElement(ShieldCheck, { className: "w-4 h-4 ml-2 fill-gray-400" })),
                                    isRoomMuted && (React.createElement(BellSlash, { className: "w-4 h-4 ml-2 fill-gray-400" })),
                                    isPinned && (React.createElement(Pin, { className: "w-4 h-4 ml-2 fill-gray-400" })))),
                            !hideOptions && (React.createElement("div", { className: "chat-dropdown relative hidden group-hover:block" },
                                React.createElement("div", { className: "chat-toggle", onClick: (e) => openMenu(e) },
                                    React.createElement(EllipsisVertical, { className: "w-5 h-5 fill-gray-500" })),
                                isOpen && (React.createElement("div", { ref: menuRef, className: "listitem-dropdown" },
                                    React.createElement("div", { onClick: (e) => {
                                            e.stopPropagation();
                                            muteRoom(roomId);
                                            setIsOpen(false);
                                        } },
                                        React.createElement("div", { className: "mr-2" }, isRoomMuted ? (React.createElement(BellSlash, { className: "w-4 h-4" })) : (React.createElement(Bell, { className: "w-4 h-4" }))),
                                        isRoomMuted
                                            ? t('unmute')
                                            : t('mute')),
                                    React.createElement("div", { onClick: (e) => {
                                            e.stopPropagation();
                                            onPin(roomId);
                                            setIsOpen(false);
                                        } },
                                        React.createElement("div", { className: "mr-2" },
                                            React.createElement(Pin, { className: "w-4 h-4" })),
                                        isPinned
                                            ? t('unpin')
                                            : t('pin')),
                                    React.createElement("div", { onClick: () => {
                                            dispatch(toggleChatLeaveRoom({
                                                roomId: room.id,
                                            }));
                                            setIsOpen(false);
                                        }, "data-type": 'modal-btn' },
                                        React.createElement("div", { className: "mr-2" },
                                            React.createElement(Trash, { className: "w-4 h-4" })),
                                        t('leave'))))))),
                        React.createElement("div", { className: `text-sm truncate w-80 ${lastEvent && lastEvent?.readState < 1
                                ? 'font-semibold italic'
                                : 'font-light text-slate-500'}` }, room.userTyping && room.userTyping.length > 0
                            ? `${room.userTyping[0].name} schreibt...`
                            : mediaType
                                ? mediaType
                                : parseMessage(lastEvent?.content?.body, !!lastEvent?.replyTo).body)))))));
};
export default ListItem;
