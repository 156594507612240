import store from './store';
import { store as rxStore } from '../../store';
import { openUploadFileModal, openUploadModal } from '../../reducer/app';
import moment from 'moment';
const getBlob = async (url) => {
    const res = await fetch(url);
    return await res.blob();
};
const getAvatarAsBlob = async (userId) => {
    const found = store.userImages.find((i) => i.sender === userId);
    if (found) {
        return found.url;
    }
    const user = store.client.getUser(userId);
    if (!user)
        throw new Error('no user');
    const url = store.client.mxcUrlToHttp(user?.avatarUrl || "");
    if (!url)
        return;
    getBlob(url).then((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        store.userImages.push({
            sender: userId,
            url: blobUrl,
        });
        return blobUrl;
    });
};
const handleFile = (file) => {
    const fileName = file?.name || `Screenshot-${moment().format('DD-MM-YYYY_hh:mm:ss')}.png`;
    if (file.type.indexOf('image/') !== -1) {
        rxStore.dispatch(openUploadModal({ files: [URL.createObjectURL(file)], fileName }));
    }
    else {
        rxStore.dispatch(openUploadFileModal({ files: [URL.createObjectURL(file)], fileName, fileType: file.type, fileSize: file.size }));
    }
};
export { getAvatarAsBlob, getBlob, handleFile };
