import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideActiveModal, selectActiveModal } from '../../reducer/app';
import ChatLeaveRoomModal from './ChatLeaveRoomModal';
import ImageModal from './ImageModal';
import JoinRoomModal from './JoinRoomModal';
import RoomListModal from './RoomListModal';
import UploadModal from './UploadModal';
import ChatMessageDeleteModal from './ChatMessageDeleteModal';
import UploadFileModal from './UploadFileModal';
import ShareMessageModal from './ShareMessageModal';
const ModalIndex = () => {
    const dispatch = useAppDispatch();
    const activeModal = useAppSelector(selectActiveModal);
    React.useEffect(() => {
        const hideModal = (evt) => {
            const selected = document.querySelector('[data-type=modal]');
            if (!selected)
                return evt;
            if (!selected?.contains(evt.target)) {
                dispatch(hideActiveModal());
            }
        };
        if (activeModal !== '') {
            window.addEventListener('mousedown', hideModal);
        }
        return () => {
            window.removeEventListener('mousedown', hideModal);
        };
    }, [activeModal]);
    switch (activeModal) {
        case 'userRoomList':
            return React.createElement(RoomListModal, null);
        case 'joinRoomModal':
            return React.createElement(JoinRoomModal, null);
        case 'chatLeaveRoom':
            return React.createElement(ChatLeaveRoomModal, null);
        case 'chatMessageDeleteModal':
            return React.createElement(ChatMessageDeleteModal, null);
        case 'chatShareMessageModal':
            return React.createElement(ShareMessageModal, null);
        case 'uploadModal':
            return React.createElement(UploadModal, null);
        case 'uploadFileModal':
            return React.createElement(UploadFileModal, null);
        case 'imageModal':
            return React.createElement(ImageModal, null);
        default:
            return React.createElement("div", { hidden: true });
    }
};
export default ModalIndex;
