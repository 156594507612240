import * as React from 'react';
import mcli from '../../libs/matrix';
import { downloadFile } from '../../libs/common/download';
import { getDecryptedImageUrl } from '../../libs/matrix/message';
const File = React.memo(({ file, name, url }) => {
    const download = () => {
        if (!file)
            return;
        getDecryptedImageUrl(file)
            .then((resUrl) => {
            if (resUrl) {
                downloadFile(resUrl, name);
            }
        })
            .catch(console.error);
    };
    return (React.createElement("div", { className: "h-fill w-fill" },
        React.createElement("div", { className: "bg-slate-200 p-2 text-gray-800 rounded" }, name),
        url ? (React.createElement("a", { className: "chat-message-download", onClick: () => downloadFile(mcli.mxcUrlToHttp(url) || url, name) }, "download")) : (React.createElement("a", { className: "chat-message-download", onClick: download }, "download"))));
});
export default File;
