import mcli from '.';
import { getNestedReplyText } from './reply';
const ROOM_STATUS_EVENTS = ['m.room.member', 'm.room.name', 'm.room.topic'];
export const createTEvent = (mevent, redaction = false) => {
    const room = mcli.getRoom(mevent.getRoomId());
    if (!room)
        return;
    const resolvedMembers = [];
    room.getMembers().forEach((m) => {
        resolvedMembers.push({
            userId: m.userId,
            imageUrl: m.getAvatarUrl(mcli.baseUrl, 49, 49, 'crop', true, true) || '',
            name: m.name,
            membership: m.membership || '',
            typing: false,
            roomId: m.roomId,
            presence: m.user?.presence || '',
        });
    });
    const readEventId = room.getEventReadUpTo(mcli.getUserId() || '');
    const id = mevent?.getId() || '';
    let readState = 0;
    const users = room.getUsersReadUpTo(mevent);
    const haveRead = resolvedMembers.filter((member) => users.includes(member.userId));
    readState =
        resolvedMembers.length === haveRead.length
            ? 2
            : haveRead.length > 0
                ? 1
                : 2;
    return {
        id,
        type: mevent.getType(),
        content: mevent.getContent(),
        sender: mevent?.getSender() || '',
        timestamp: mevent.localTimestamp,
        roomId: mevent.getRoomId() || '',
        replyTo: mevent.replyEventId || null,
        readState,
        isStatusEvent: ROOM_STATUS_EVENTS.includes(mevent.getType()),
        isSending: mevent.isSending(),
        isEncrypted: mevent.isEncrypted(),
        isRedacted: redaction || mevent.isRedacted(),
        lastEventRead: readEventId || '',
        replyEvent: getNestedReplyText(mevent),
        relation: mevent.getRelation(),
        threadRootId: mevent.threadRootId,
    };
};
export async function setDmRoom(roomId, userId) {
    if (mcli != null) {
        const mDirectEvent = mcli.getAccountData('m.direct');
        let dmRoomMap = {};
        if (mDirectEvent !== undefined)
            dmRoomMap = { ...mDirectEvent?.getContent() }; // copy as we will mutate
        // remove it from the lists of any others users
        // (it can only be a DM room for one person)
        Object.keys(dmRoomMap).forEach((id) => {
            const roomList = dmRoomMap[id];
            if (id !== userId) {
                const indexOfRoom = roomList.indexOf(roomId);
                if (indexOfRoom > -1) {
                    roomList.splice(indexOfRoom, 1);
                }
            }
        });
        // now add it, if it's not already there
        if (userId) {
            const roomList = dmRoomMap[userId] || [];
            if (roomList.indexOf(roomId) === -1) {
                roomList.push(roomId);
            }
            dmRoomMap[userId] = roomList;
        }
        await mcli.setAccountData('m.direct', dmRoomMap);
    }
}
