export const saveLocal = (name, data) => {
    if (typeof window !== 'undefined' && localStorage) {
        localStorage.setItem(name, JSON.stringify({
            ...data,
        }));
    }
};
export const loadLocal = (name) => {
    if (typeof window !== 'undefined' && localStorage) {
        const storeFromLocal = localStorage.getItem(name);
        if (storeFromLocal)
            return JSON.parse(storeFromLocal);
    }
};
export const clearAll = () => {
    if (typeof window !== 'undefined' && localStorage) {
        localStorage.removeItem("mapp-sdk");
    }
};
