import * as React from 'react';
import { parseMessage, urlify } from '../../libs/matrix/message';
import Quote from './Quote';
const Message = ({ message, isReply, }) => {
    let isEmoji = false;
    if (message?.length === 2) {
        const regex_emoji = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/u;
        isEmoji = !!message.match(regex_emoji);
    }
    const { html, body } = parseMessage(message);
    return (React.createElement("div", { className: `break-words whitespace-pre-wrap text-gray-700 ${isReply && message && message?.length > 500 ? 'lineclamp' : ''}  ${isEmoji ? 'text-4xl' : 'text-base'}` }, html ? html : body));
};
const HtmlMessage = ({ htmlMessage, body }) => {
    let isEmoji = false;
    return (React.createElement("div", { className: `break-words whitespace-pre-wrap p-2 ${isEmoji ? 'text-4xl' : 'text-base'}`, dangerouslySetInnerHTML: { __html: body.includes('https://') ? urlify(body) : htmlMessage } }));
};
const Text = ({ me, message, replyTo, roomId, isReply, callback, isForwarded, }) => {
    const { html, body } = parseMessage(message, !!replyTo || !!isReply);
    React.useEffect(() => {
        if (!replyTo)
            callback();
    }, []);
    return (React.createElement("div", { className: "flex flex-col" },
        replyTo && (React.createElement(Quote, { replyTo: replyTo, roomId: roomId, me: me, callback: callback })),
        html ? (React.createElement(HtmlMessage, { htmlMessage: html, body: body })) : (React.createElement(Message, { message: body, isReply: isReply }))));
};
export default Text;
