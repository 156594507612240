import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideActiveModal, selectConfiguration, } from '../../reducer/app';
import { updateSelectedRoom, } from '../../reducer/matrix';
import Cross from '../Icons/Cross';
import ModalSkelleton from '../ModalSkelleton';
import mcli from '../../libs/matrix';
import { EventType, Preset } from 'matrix-js-sdk';
import { useTranslation } from 'react-i18next';
import ChatUserRoomTable from '../ChatUserRoomTable';
const JoinRoomModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const category = 'user';
    const [selected, setSelected] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const configuration = useAppSelector(selectConfiguration);
    // const encryptionOption = mappData?.chatEncryptionOption || 0;
    const encryptionOption = configuration?.chatEncryptionOption || 2;
    const doneCreateChat = () => {
        dispatch(hideActiveModal());
        setIsLoading(false);
    };
    const createChat = async (userId, encrypted) => {
        if (!userId)
            return;
        setIsLoading(true);
        //find Room
        const dmRoomEvent = mcli.getAccountData('m.direct');
        let dmRoomMap = {};
        if (dmRoomEvent !== undefined) {
            dmRoomMap = { ...dmRoomEvent?.getContent() };
        }
        const foundRoom = dmRoomMap[userId]?.find((roomId) => {
            const room = mcli.getRoom(roomId);
            if (room) {
                const members = room.getJoinedMembers();
                return members.length > 1;
            }
            return false;
        });
        //CHAT ENCRYPTION OPTIONS  0 = verschlüsselt , 1 = nicht verschlüsselt, 2 = auswählen
        if (foundRoom) {
            dispatch(updateSelectedRoom(foundRoom));
            doneCreateChat();
            return;
        }
        const res = await mcli.createRoom({
            invite: [userId],
            preset: Preset.TrustedPrivateChat,
            is_direct: true,
        });
        await setDmRoom(res.room_id, userId);
        if (encrypted) {
            await encryptRoom(res.room_id);
        }
        dispatch(updateSelectedRoom(res.room_id));
        doneCreateChat();
    };
    async function setDmRoom(roomId, userId) {
        if (mcli != null) {
            const mDirectEvent = mcli.getAccountData('m.direct');
            let dmRoomMap = {};
            if (mDirectEvent !== undefined)
                dmRoomMap = { ...mDirectEvent?.getContent() }; // copy as we will mutate
            // remove it from the lists of any others users
            // (it can only be a DM room for one person)
            Object.keys(dmRoomMap).forEach((id) => {
                const roomList = dmRoomMap[id];
                if (id != userId) {
                    const indexOfRoom = roomList.indexOf(roomId);
                    if (indexOfRoom > -1) {
                        roomList.splice(indexOfRoom, 1);
                    }
                }
            });
            // now add it, if it's not already there
            if (userId) {
                const roomList = dmRoomMap[userId] || [];
                if (roomList.indexOf(roomId) == -1) {
                    roomList.push(roomId);
                }
                dmRoomMap[userId] = roomList;
            }
            await mcli.setAccountData('m.direct', dmRoomMap);
        }
    }
    const encryptRoom = async (roomId) => {
        const createdRoom = mcli.getRoom(roomId);
        if (!createdRoom)
            return;
        await mcli.sendStateEvent(roomId, EventType.RoomEncryption, {
            algorithm: 'm.megolm.v1.aes-sha2',
        });
        await mcli.setRoomEncryption(roomId, {
            algorithm: 'm.megolm.v1.aes-sha2',
        });
        const members = (await createdRoom.getEncryptionTargetMembers()).map((x) => x['userId']);
        const memberkeys = await mcli.downloadKeys(members);
        for (const userId in memberkeys) {
            for (const deviceId in memberkeys.get(userId)) {
                await mcli.setDeviceVerified(userId, deviceId);
            }
        }
    };
    return (React.createElement(ModalSkelleton, null,
        React.createElement("div", { className: "chat-side-menu flex flex-col justify-between" },
            React.createElement("div", { className: "flex items-center justify-between mb-6" },
                React.createElement("h4", null, t('new_chat_header')),
                React.createElement("div", { className: "flex justify-end cursor-pointer", onClick: () => dispatch(hideActiveModal()) },
                    React.createElement(Cross, { className: "w-4 h-4 fill-gray-600" }))),
            React.createElement(ChatUserRoomTable, { selected: selected, setSelected: setSelected }),
            encryptionOption === 2 && category === 'user' ? (React.createElement("div", { className: "mt-4 flex items-center justify-between w-full" },
                React.createElement("button", { className: "mapp-button mapp-button-primary", disabled: !selected || isLoading, onClick: () => {
                        if (category === 'user') {
                            // @ts-ignore
                            createChat(selected.userId, true);
                        }
                    } }, t('start_chat_encrypted')),
                React.createElement("button", { className: "mapp-button mapp-button-primary", disabled: !selected || isLoading, onClick: () => {
                        if (category === 'user') {
                            // @ts-ignore
                            createChat(selected.userId, false);
                        }
                    } }, t('start_chat')))) : (React.createElement("div", { className: "mt-4 flex justify-end w-full" },
                React.createElement("button", { className: "mapp-button mapp-button-primary", disabled: !selected || isLoading, onClick: () => {
                        if (category === 'user') {
                            // @ts-ignore
                            createChat(
                            // @ts-ignore
                            selected.userId
                            // encryptionOption === 0
                            );
                        }
                    } }, t('start_chat')))))));
};
export default JoinRoomModal;
