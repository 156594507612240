import { RoomMemberEvent } from 'matrix-js-sdk';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import { paginateRoomAsync, selectMatrixEvent, selectRoom, selectSelectedRoom, } from '../../reducer/matrix';
import ChatMessage from '../ChatMessage';
import ChevronDown from '../Icons/ChevronDown';
import TypingIndicator from '../TypingIndicator';
import moment from 'moment';
import Circle from '../Loader/Circle';
const allowedEventTypes = [
    'm.room.member',
    'm.room.name',
    'm.room.topic',
    'm.room.message',
    'm.room.encrypted',
];
const ChatMessageWindow = React.memo(({ reply }) => {
    const dispatch = useAppDispatch();
    const roomId = useAppSelector(selectSelectedRoom);
    const room = useAppSelector((state) => selectRoom(state, roomId || ''));
    const lastEventId = room?.events[room.events.length - 1]?.id || '';
    const matrixEvent = useAppSelector((state) => selectMatrixEvent(state, lastEventId));
    const scrollRef = React.useRef(null);
    const containerRef = React.useRef(null);
    const endDiv = React.useRef(null);
    const [isFinished, setIsFinished] = React.useState(true);
    const [loadingBackwards, setLoadingBackwards] = React.useState(false);
    const [userThatTyping, setUserThatTyping] = React.useState([]);
    const [scroller, setScroller] = React.useState(false);
    React.useEffect(() => {
        if (!room)
            return;
        const isLastMessageEvent = room.events[room.events.length - 1]?.type === 'm.room.message';
        const isLastEventMine = room.events[room.events.length - 1]?.sender === mcli.getUserId();
        if (!scroller || (isLastMessageEvent && isLastEventMine)) {
            scrollDown();
        }
        mcli.on(RoomMemberEvent.Typing, updateTyping);
        if (!matrixEvent?.getId() || lastEventId.includes(':'))
            return;
        mcli.sendReadReceipt(matrixEvent || null).catch(console.error);
        const eId = matrixEvent.getId();
        if (roomId !== undefined && eId !== undefined) {
            mcli.setRoomReadMarkers(roomId, eId).catch(console.error);
        }
        return () => {
            mcli.removeListener(RoomMemberEvent.Typing, updateTyping);
        };
    }, [room, room?.events]);
    const updateTyping = (_event, member) => {
        const isMe = mcli.getUserId() === member.userId;
        if (isMe)
            return;
        if (member.roomId !== roomId)
            return;
        typingCheck(member);
    };
    const typingCheck = (member) => {
        if (member.typing &&
            !userThatTyping.find((user) => user.userId === member.userId)) {
            setUserThatTyping([...userThatTyping, member]);
        }
        if (!member.typing) {
            setUserThatTyping(userThatTyping.filter((_member) => _member.name !== member.name));
            roomId && mcli.sendTyping(roomId, false, 0);
        }
    };
    const checkScrolled = () => {
        if (!roomId)
            return;
        if (!containerRef?.current || !scrollRef.current || loadingBackwards)
            return;
        const scrollElement = scrollRef.current.getBoundingClientRect();
        const chatWindow = containerRef.current.getBoundingClientRect();
        const verticallyVisible = scrollElement.top >= 0 && scrollElement.bottom <= chatWindow.bottom;
        if (verticallyVisible && !loadingBackwards) {
            setLoadingBackwards(true);
            // Save the position of the last chat message
            const lastMessage = containerRef.current.lastElementChild;
            const lastMessagePosition = lastMessage ? lastMessage.getBoundingClientRect().top : 0;
            dispatch(paginateRoomAsync({ roomId, page: 25 })).finally(() => {
                // Restore the position of the last chat message after loading new data
                if (lastMessage) {
                    const newLastMessagePosition = lastMessage.getBoundingClientRect().top;
                    if (containerRef && containerRef.current)
                        containerRef.current.scrollTop += newLastMessagePosition - lastMessagePosition;
                }
                setLoadingBackwards(false);
            });
        }
        const chatWindowEl = containerRef.current;
        const scrolling = chatWindowEl.scrollHeight - chatWindowEl.scrollTop - 10 >
            chatWindowEl.clientHeight;
        setScroller(scrolling);
    };
    const scrollDown = () => {
        if (!endDiv.current)
            return;
        endDiv.current.scrollIntoView();
    };
    const isFinish = (max) => {
        let done = 0;
        return () => {
            done += 1;
            if (done >= max && !isFinished) {
                setIsFinished(true);
                scrollDown();
            }
        };
    };
    if (!roomId)
        return React.createElement(React.Fragment, null);
    if (!room)
        return React.createElement(React.Fragment, null);
    const triggerFinish = isFinish(room.events.length - 1);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `chat-window`, ref: containerRef, onScroll: checkScrolled },
            room.events.map((m_event, index) => {
                const sender = mcli.getUser(m_event.sender);
                if (allowedEventTypes.includes(m_event.type) &&
                    sender?.displayName !== 'matrixadmin') {
                    const nextEvent = room.events[index + 1];
                    const currentEvent = room.events[index];
                    const currentDate = new Date(currentEvent?.timestamp);
                    const nextDate = new Date(nextEvent?.timestamp);
                    const isSameDay = currentDate.getDate() === nextDate.getDate() &&
                        currentDate.getMonth() === nextDate.getMonth() &&
                        currentDate.getFullYear() ===
                            nextDate.getFullYear();
                    if (!isSameDay &&
                        !nextEvent?.isStatusEvent &&
                        nextDate.getDate().toString() !== 'NaN') {
                        return (React.createElement(React.Fragment, null,
                            index === 0 && loadingBackwards && (React.createElement("div", { className: "flex justify-center items-center w-full my-2" },
                                React.createElement(Circle, null))),
                            index === 2 && React.createElement("div", { ref: scrollRef }),
                            React.createElement(ChatMessage, { key: m_event.id, id: m_event.id, isDirect: room.isDirect, reply: reply, callback: triggerFinish }),
                            React.createElement("div", { className: "flex justify-center items-center w-full my-2" },
                                React.createElement("div", { className: "border border-primary-light h-[1px] w-[28%]" }),
                                React.createElement("div", { className: `flex justify-center text-primary-light text-xs rounded-md p-3 min-w-fit max-w-fit mx-2` }, moment(nextDate).calendar()),
                                React.createElement("div", { className: "border border-primary-light h-[1px] w-[28%]" }))));
                    }
                    return (React.createElement(React.Fragment, null,
                        index === 0 && loadingBackwards && (React.createElement("div", { className: "flex justify-center items-center w-full my-2" },
                            React.createElement(Circle, null))),
                        index === 2 && React.createElement("div", { ref: scrollRef }),
                        React.createElement(ChatMessage, { isDirect: room.isDirect, key: m_event.id, id: m_event.id, reply: reply, callback: triggerFinish })));
                }
            }),
            room.userTyping.length > 0 && (React.createElement(TypingIndicator, { typingUsers: room.userTyping })),
            React.createElement("div", { className: "mt-4 h-6 enddiv", ref: endDiv },
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement("br", null))),
        scroller && (React.createElement("div", { className: "chat-window-scroller", onClick: scrollDown },
            React.createElement(ChevronDown, { className: "" })))));
});
export default ChatMessageWindow;
