import * as React from 'react';
import { useTranslation } from 'react-i18next';
const PasswordChecker = ({ password, passwordRepeat, disableFn, }) => {
    const { t } = useTranslation();
    const [digitCheck, setDigitCheck] = React.useState(false);
    const [countCheck, setCountCheck] = React.useState(false);
    const [equalCheck, setEqualCheck] = React.useState(false);
    const [specialCharakterCheck, setSpecialCharakterCheck] = React.useState(false);
    const [upperLowerCheck, setUpperLowerCheck] = React.useState(false);
    React.useEffect(() => {
        passwordCheck(password);
    }, [password, passwordRepeat]);
    const passwordCheck = (password) => {
        const digitRexExp = new RegExp('(?=.*[0-9])');
        const digitCheck = digitRexExp.test(password);
        const countCheck = password.length >= 8;
        const specialCharRegExp = new RegExp("[$&+,:;=?@#|'<>.^*()%!-]");
        const specialCharakterCheck = specialCharRegExp.test(password);
        const upperLowerCheck = allCases(password);
        const equalCheck = password === passwordRepeat;
        digitCheck ? setDigitCheck(!!digitCheck) : setDigitCheck(false);
        specialCharakterCheck
            ? setSpecialCharakterCheck(!!specialCharakterCheck)
            : setSpecialCharakterCheck(false);
        upperLowerCheck
            ? setUpperLowerCheck(upperLowerCheck)
            : setUpperLowerCheck(false);
        equalCheck ? setEqualCheck(equalCheck) : setEqualCheck(false);
        countCheck ? setCountCheck(countCheck) : setCountCheck(false);
        if (!digitCheck ||
            !countCheck ||
            !specialCharakterCheck ||
            !upperLowerCheck ||
            !equalCheck) {
            disableFn(true);
        }
        else {
            disableFn(false);
        }
    };
    const allCases = (string) => {
        let upper = false, lower = false;
        for (const character of string) {
            if (character.toUpperCase() === character.toLowerCase())
                continue;
            upper ||= character === character.toUpperCase();
            lower ||= character === character.toLowerCase();
            if (upper && lower)
                return true;
        }
        return false;
    };
    return (React.createElement("div", { className: "password-check" },
        React.createElement("div", { className: `${countCheck ? 'text-green-500' : 'text-gray-500'}` }, t('password_check_1')),
        React.createElement("div", { className: `${upperLowerCheck ? 'text-green-500' : 'text-gray-500'}` }, t('password_check_2')),
        React.createElement("div", { className: `${specialCharakterCheck ? 'text-green-500' : 'text-gray-500'}` }, t('password_check_3')),
        React.createElement("div", { className: `${digitCheck ? 'text-green-500' : 'text-gray-500'}` }, t('password_check_4')),
        React.createElement("div", { className: `${equalCheck && passwordRepeat && password
                ? 'text-green-500'
                : 'text-gray-500'}` }, t('password_check_5'))));
};
export default PasswordChecker;
