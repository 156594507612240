import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import { hideActiveModal, selectChatLeaveRoomModal } from '../../reducer/app';
import { selectSelectedRoom, updateSelectedRoom } from '../../reducer/matrix';
import ModalSkelleton from '../ModalSkelleton';
import { selectRoom } from '../../reducer/matrix';
import { useTranslation } from 'react-i18next';
const ChatLeaveRoomModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedRoomId = useAppSelector(selectSelectedRoom);
    const selectChatLeaveRoom = useAppSelector(selectChatLeaveRoomModal);
    const roomId = selectChatLeaveRoom?.roomId || selectedRoomId;
    const room = useAppSelector((state) => selectRoom(state, roomId || ''));
    const leaveRoom = (id) => {
        mcli.leave(id);
        dispatch(updateSelectedRoom(""));
    };
    if (!room || !room?.id)
        return React.createElement(React.Fragment, null);
    return (React.createElement(ModalSkelleton, null,
        React.createElement("div", { className: "p-4" },
            React.createElement("div", null,
                React.createElement("h4", null, t('leave_chat_text')),
                React.createElement("div", { className: "mt-2" }, t('leave_chat_warning'))),
            React.createElement("div", { className: "flex items-center justify-between mt-4" },
                React.createElement("button", { className: "mapp-button mapp-button-secondary", onClick: () => dispatch(hideActiveModal()) }, t('abort')),
                React.createElement("button", { className: "mapp-button mapp-button-danger", onClick: () => {
                        leaveRoom(room.id);
                        dispatch(hideActiveModal());
                    } },
                    React.createElement("b", null, t('leave_chat')))))));
};
export default ChatLeaveRoomModal;
