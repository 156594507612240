import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { sendMedia, sendVideo } from '../../libs/matrix/message';
import { hideActiveModal, selectUploadFileModal } from '../../reducer/app';
import { selectRoom, selectSelectedRoom } from '../../reducer/matrix';
import ModalSkelleton from '../ModalSkelleton';
const UploadFileModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const roomId = useAppSelector(selectSelectedRoom);
    const room = useAppSelector((state) => selectRoom(state, roomId || ""));
    const data = useAppSelector(selectUploadFileModal);
    const [sending, setSending] = React.useState(false);
    const [error, setError] = React.useState(false);
    const file = data && data?.files?.length > 0 && data.files[0];
    const fileName = data && data?.fileName;
    const fileType = data && data?.fileType || "text/plain";
    const fileSize = data && data?.fileSize;
    const handleFileUpload = async () => {
        if (!roomId || !room || !fileName || !fileType)
            return;
        setError(false);
        setSending(true);
        let blob = await fetch(file).then((r) => r.blob());
        let type = 'm.file';
        if (fileType.substring(0, 5) === 'video') {
            type = 'm.video';
        }
        if (fileType.substring(0, 5) === 'image') {
            return;
        }
        if (type === 'm.video') {
            var videoFile = new File([blob], fileName, { type: fileType });
            return sendVideo(roomId, videoFile, false, room?.isEncrypted).then(() => {
                dispatch(hideActiveModal());
                setSending(false);
            }).catch(() => {
                setSending(false);
                setError(true);
            });
        }
        return sendMedia(roomId, type, blob, fileName).then(() => {
            dispatch(hideActiveModal());
            setSending(false);
        }).catch(() => {
            setSending(false);
            setError(true);
        });
    };
    if (!roomId)
        return React.createElement(React.Fragment, null);
    const beautifySize = (sizeInBytes) => {
        if (sizeInBytes > 1_000_000)
            return Math.floor((sizeInBytes / (1024 * 1024))) + " MB";
        if (sizeInBytes > 100_000)
            return Math.floor((sizeInBytes / (1024))) + " KB";
        return sizeInBytes + " B";
    };
    return (React.createElement(ModalSkelleton, null,
        React.createElement("div", { className: "p-4" },
            React.createElement("div", { className: "flex items-center justify-between" },
                React.createElement("span", { className: "font-semibold text-lg" }, t('upload_file')),
                React.createElement("div", { onClick: () => dispatch(hideActiveModal()) },
                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 384 512", className: "w-5 h-5 cursor-pointer fill-gray-200 hover:fill-gray-250" },
                        React.createElement("path", { d: "M378.4 440.6c8.531 10.16 7.203 25.28-2.938 33.81C370.9 478.2 365.5 480 360 480c-6.844 0-13.64-2.906-18.39-8.562L192 293.3l-149.6 178.1C37.63 477.1 30.83 480 23.98 480c-5.453 0-10.92-1.844-15.42-5.625c-10.14-8.531-11.47-23.66-2.938-33.81L160.7 256L5.625 71.44C-2.906 61.28-1.578 46.16 8.563 37.63C18.69 29.08 33.84 30.39 42.38 40.56L192 218.7l149.6-178.1c8.547-10.17 23.67-11.47 33.81-2.938s11.47 23.66 2.938 33.81L223.3 256L378.4 440.6z" })))),
            React.createElement("div", { className: "flex items-center justify-center flex-col" },
                file && React.createElement("h4", null, fileName),
                file && fileSize && React.createElement("span", null, beautifySize(fileSize)),
                error && React.createElement("p", { style: { color: "red" } }, t('upload_error'))),
            React.createElement("div", null,
                React.createElement("button", { className: "mapp-button mapp-button-primary", disabled: sending, onClick: handleFileUpload }, t('upload_file'))))));
};
export default UploadFileModal;
