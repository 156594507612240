import { deriveKey } from 'matrix-js-sdk/lib/crypto/key_passphrase';
import store from './store';
const getSecretStorageKey = async ({ keys: keyInfos, }) => {
    if (!store?.phrase)
        return null;
    // Use MatrixClient#secretStorage and SecretStorage.ServerSideSecretStorage#getDefaultKeyId
    let keyId = await store.client.secretStorage?.getDefaultKeyId();
    let keyInfo;
    if (keyId) {
        keyInfo = keyInfos[keyId];
        if (!keyInfo) {
            keyId = null;
        }
    }
    if (!keyId) {
        // if no default SSSS key is set, fall back to a heuristic of using the
        // only available key, if only one key is set
        const keyInfoEntries = Object.entries(keyInfos);
        if (keyInfoEntries.length > 1) {
            throw new Error('Multiple storage key requests not implemented');
        }
        [keyId, keyInfo] = keyInfoEntries[0];
    }
    if (!keyInfo)
        return null;
    const derivedKey = await deriveKey(store.phrase, keyInfo.passphrase.salt, keyInfo.passphrase.iterations);
    store.phrase = '';
    return [keyId, derivedKey];
};
export default getSecretStorageKey;
