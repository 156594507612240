import * as React from 'react';
import mcli from "../../libs/matrix";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideActiveModal } from '../../reducer/app';
import { selectRoom, selectSelectedRoom } from '../../reducer/matrix';
import Cross from '../Icons/Cross';
import ModalSkelleton from '../ModalSkelleton';
const RoomListModal = () => {
    const dispatch = useAppDispatch();
    const selectedRoomId = useAppSelector(selectSelectedRoom);
    const room = selectedRoomId ? useAppSelector((state) => selectRoom(state, selectedRoomId)) : undefined;
    if (!selectSelectedRoom || !room)
        return React.createElement(React.Fragment, null);
    let avatar = room.imageUrl;
    if (!avatar && room.members.length === 2) {
        room.members.forEach((member) => {
            if (member.userId !== mcli.getUserId()) {
                avatar = member.imageUrl;
            }
        });
    }
    return (React.createElement(ModalSkelleton, null,
        React.createElement("div", { className: `chat-side-menu` },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "w-full flex justify-end cursor-pointer", onClick: () => dispatch(hideActiveModal()) },
                    React.createElement(Cross, { className: "w-4 h-4 fill-gray-600" })),
                React.createElement("div", { className: "flex flex-col items-center justify-center" },
                    React.createElement("div", null,
                        React.createElement("img", { className: "rounded-full shadow", src: avatar })),
                    React.createElement("div", { className: "mt-3 font-semibold text-lg" }, room.name)),
                React.createElement("div", { className: "overflow-y-scroll max-h-96" }, room.members.map((member) => {
                    const mAvatar = member.imageUrl;
                    return (React.createElement("div", { key: member.userId, className: "flex items-center p-2" },
                        React.createElement("div", null, mAvatar ? (React.createElement("img", { className: "rounded-full shadow w-10 h-10 bg-primary-full text-white", src: mAvatar, alt: member.name.substring(1) })) : (React.createElement("div", { className: "rounded-full flex justify-center items-center w-10 h-10 bg-primary-full text-white uppercase" }, member.name.substring(0, 1)))),
                        React.createElement("div", { className: "ml-2" }, member.name)));
                }))))));
};
export default RoomListModal;
