import _ from 'lodash';
import { NotificationCountType, PushRuleActionName, RoomEvent, RoomMemberEvent, UserEvent, } from 'matrix-js-sdk';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import pkg from '../../package.json';
import { useAppDispatch, useAppSelector } from '../hooks';
import sdk from '../libs/mapp/sdk';
import mcli, { auth } from '../libs/matrix';
import { setDmRoom } from '../libs/matrix/event';
import { handleFile } from '../libs/matrix/image';
import { textMessage } from '../libs/matrix/message';
import { getConfigurationAsync, toggleJoinRoomModal, updateState, } from '../reducer/app';
import { getRoomAsync, leaveRoom, selectSelectedRoom, updateMemberTyping, } from '../reducer/matrix';
import ChatInput from './ChatInput';
import ChatMessageWindow from './ChatMessageWindow';
import ChatRoomTopbar from './ChatRoomTopbar';
import ChatRoomsSidebar from './ChatRoomsSidebar';
import Gear from './Icons/Gear';
import Pen from './Icons/Pen';
import ModalIndex from './Modal';
import NetworkErrorTooltip from './Tooltip/NetworkErrorTooltip';
import Bell from './Notification/Bell';
import { useNavigate } from 'react-router-dom';
const lastRoomId = '';
let audio;
const App = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = window.innerWidth < 621;
    const selectedRoomId = useAppSelector(selectSelectedRoom);
    const dispatch = useAppDispatch();
    const lastSoundTime = React.useRef(new Date());
    audio = audio || new Audio('/chat_alert_1.mp3');
    const menuRef = React.useRef(null);
    const [searchPattern, setSearchPattern] = React.useState('');
    const [notifications, setNotifications] = React.useState([]);
    const [meAvatar, setMeAvatar] = React.useState();
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [messageToReply, setMessageToReply] = React.useState();
    React.useEffect(() => {
        const switched = selectedRoomId !== lastRoomId;
        if (switched) {
            closeReply();
        }
        const userId = mcli.getUserId();
        if (!userId)
            return;
        const user = mcli.getUser(userId);
        if (!user)
            return;
        dispatch(getConfigurationAsync());
        setMeAvatar(mcli.mxcUrlToHttp(user.avatarUrl || '') || undefined);
        requestNotificaton();
        sdk.Configuration.get().then((res) => {
            applyTheme(res.theme);
        });
        mcli.on(RoomMemberEvent.Membership, updateRoomMembership);
        mcli.on(RoomEvent.Timeline, updateRoom);
        mcli.on(RoomEvent.Tags, tagAccDataUpdate);
        mcli.on(RoomEvent.AccountData, tagAccDataUpdate);
        mcli.on(RoomMemberEvent.Typing, updateTyping);
        mcli.on(UserEvent.Presence, updatePresence);
        mcli.on(RoomEvent.Name, () => updateName);
        mcli.on(RoomEvent.MyMembership, updateMembership);
        const notifyFn = setTimeout(() => {
            mcli.on(RoomEvent.Timeline, sendNotification);
        }, 5000);
        document.addEventListener('visibilitychange', checkNotifications);
        document.addEventListener('mousedown', closeMenu);
        return () => {
            mcli.removeListener(RoomMemberEvent.Membership, updateRoomMembership);
            mcli.removeListener(RoomMemberEvent.Typing, updateTyping);
            mcli.removeListener(RoomEvent.Timeline, updateRoom);
            mcli.removeListener(RoomEvent.Timeline, sendNotification);
            mcli.removeListener(UserEvent.Presence, updatePresence);
            mcli.removeListener(RoomEvent.Tags, tagAccDataUpdate);
            mcli.removeListener(RoomEvent.AccountData, tagAccDataUpdate);
            mcli.removeListener(RoomEvent.Name, updateName);
            mcli.removeListener(RoomEvent.MyMembership, updateMembership);
            clearTimeout(notifyFn);
            document.removeEventListener('visibilitychange', checkNotifications);
            document.removeEventListener('mousedown', closeMenu);
        };
    }, [selectedRoomId]);
    const applyTheme = (theme) => {
        const themes = [
            'theme-classic-blue',
            'theme-classic-green',
            'theme-holiday-turquoise',
            'theme-attention-lilac',
            'theme-dark-blue',
            'theme-code-red',
            'theme-glamour-pink',
            'theme-new-orange',
            'theme-warm-yellow',
            'theme-mystic-black',
        ];
        const defaultTheme = 'theme-dark-blue';
        document.documentElement.className = '';
        const themeClass = themes[theme] || defaultTheme;
        document.documentElement.classList.add(themeClass);
    };
    const requestNotificaton = () => {
        if (typeof window !== 'undefined' &&
            !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            // @ts-ignore
            Notification.requestPermission();
        }
    };
    const notify = (message) => {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            if (Notification.permission === 'granted') {
                // tslint:disable-next-line
                new Notification(message);
            }
        }
    };
    const tagAccDataUpdate = (_evt, room) => {
        if (!room)
            return;
        dispatch(getRoomAsync(room.roomId));
    };
    const updateTyping = (_event, member) => dispatch(updateMemberTyping({
        userId: member.userId,
        imageUrl: member.getAvatarUrl(mcli.baseUrl, 49, 49, 'crop', true, true) || '',
        name: member.name,
        membership: member.membership || '',
        typing: member.typing,
        roomId: member.roomId,
    }));
    const sendNotification = (mEvent) => {
        if (mEvent.getSender() === mcli.getUserId())
            return;
        const roomId = mEvent.getRoomId();
        if (document.hasFocus() && roomId === selectedRoomId) {
            return;
        }
        if (!roomId)
            return;
        const found = notifications.find((no) => no.id === mEvent.event.event_id);
        if (!found) {
            const eventRoomId = mEvent.getRoomId();
            const room = mcli.getRoom(eventRoomId);
            const notifyObject = {
                id: eventRoomId,
                count: room?.getUnreadNotificationCount(NotificationCountType.Total),
            };
            setNotifications([...notifications, notifyObject]);
        }
        const currentDate = new Date();
        const roomPushRule = mcli.getRoomPushRule('global', roomId);
        if (!roomPushRule)
            return;
        if (!roomPushRule.actions.includes(PushRuleActionName.DontNotify) &&
            (currentDate.getTime() - lastSoundTime.current.getTime()) / 1000 > 5) {
            if (audio)
                audio.play();
            lastSoundTime.current = currentDate;
            if (mEvent.getContent()?.body) {
                notify(mEvent.getContent().body.substring(0, 128) + '...');
            }
        }
    };
    const closeMenu = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setMenuOpen(false);
        }
    };
    const updateRoomMembership = (evt, _member) => {
        const userId = mcli.getUserId();
        const roomId = evt.getRoomId();
        if (!roomId)
            return;
        if (evt.getType() === 'm.room.member') {
            if (evt.getSender() === userId) {
                const membership = evt.getContent().membership;
                if (membership === 'join') {
                    dispatch(getRoomAsync(roomId));
                }
                if (membership === 'leave' || membership === 'ban') {
                    dispatch(leaveRoom(roomId));
                }
            }
        }
    };
    const updateMembership = (room, membership) => {
        if (membership === 'invite') {
            mcli.joinRoom(room.roomId).then((r) => {
                const invitedBy = room.getDMInviter();
                if (invitedBy) {
                    setDmRoom(r.roomId, invitedBy);
                }
                dispatch(getRoomAsync(r.roomId));
            });
        }
    };
    const updateRoom = (evt, room, _toStartOfTimeline, removed, _data) => {
        if (!room)
            return;
        if (removed) {
            dispatch(leaveRoom(room.roomId));
            return;
        }
        if (evt.getType() === 'm.room.member' &&
            evt.getSender() === mcli.getUserId()) {
            const content = evt.getContent();
            if (content.membership === 'leave' ||
                content.membership === 'ban') {
                dispatch(leaveRoom(room.roomId));
                return;
            }
        }
        dispatch(getRoomAsync(room.roomId));
    };
    const updateName = (room) => {
        if (!room)
            return;
        dispatch(getRoomAsync(room.roomId));
    };
    const updatePresence = (evt, _user) => {
        const roomId = evt?.getRoomId();
        if (!roomId)
            return;
        dispatch(getRoomAsync(roomId));
    };
    const showLeft = () => {
        if (!isMobile)
            return true;
        if (selectedRoomId && selectedRoomId.length > 0)
            return false;
        return true;
    };
    const showRight = () => {
        if (!isMobile)
            return true;
        if (selectedRoomId && selectedRoomId.length > 0)
            return true;
        return false;
    };
    const replyMsg = async (content, sender, roomId, eventId, eventType, threadRootId, relation, replyEvent) => {
        if (!selectedRoomId)
            return;
        const room = mcli.getRoom(selectedRoomId);
        if (!room)
            return;
        const members = room.getMembers();
        if (!mcli || !members)
            return;
        // @ts-ignore
        const msg = await textMessage(mcli, content, roomId);
        const user = members.find((m) => m.userId === sender);
        setMessageToReply(_.cloneDeep({
            message: msg?.message ||
                msg?.messagePlain ||
                msg?.messageHtml ||
                'TEXT',
            user,
            type: eventType,
            id: eventId,
            threadRootId,
            relation,
            // @ts-ignore
            replyEvent,
            sender,
        }));
    };
    const closeReply = () => {
        setMessageToReply(null);
    };
    const checkNotifications = () => {
        setNotifications(notifications.filter((no) => no.id !== selectedRoomId));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalIndex, null),
        React.createElement("div", { className: "app" },
            showLeft() && (React.createElement("div", { className: "app__left" },
                React.createElement("div", { className: "app__topbar" },
                    React.createElement("div", { className: "flex" }, meAvatar && (React.createElement("div", null,
                        React.createElement("img", { src: meAvatar, alt: "My profile", className: "w-10 h-10 rounded-full order-1" })))),
                    React.createElement(NetworkErrorTooltip, null),
                    React.createElement("div", { className: "flex items-center justify-between w-14" },
                        React.createElement("div", { className: "cursor-pointer w-4 h-4 flex items-center justify-center", onClick: () => dispatch(toggleJoinRoomModal({ open: true })) },
                            React.createElement(Pen, { dataType: 'modal-btn', className: "h-5 w-5 fill-gray-400 hover:fill-gray-500" })),
                        React.createElement("div", null,
                            React.createElement(Bell, null)),
                        React.createElement("div", { className: "cursor-pointer w-4 h-4 flex items-center justify-center", ref: menuRef },
                            React.createElement("span", { onClick: () => setMenuOpen(!menuOpen) },
                                React.createElement(Gear, { className: "h-5 w-5 fill-gray-400 hover:fill-gray-500" })),
                            menuOpen && (React.createElement("div", { className: "relative bg-white rounded border shadow " },
                                React.createElement("div", { className: "absolute right-0 top-3 w-28 bg-white rounded-xl border shadow" },
                                    React.createElement("div", { className: "dropdown-item", onClick: () => dispatch(updateState('no_restore')) }, t('backup')),
                                    React.createElement("div", { className: "dropdown-item", onClick: () => {
                                            sdk.Auth.logout();
                                            auth.logout().finally(() => {
                                                navigate('/login');
                                            });
                                        } }, t('sign_out')))))))),
                React.createElement("div", { className: "flex flex-col w-sm overflow-x-auto overflow-y-auto h-full" },
                    React.createElement("div", { className: "my-2 flex items-center justify-center w-full px-4" },
                        React.createElement("div", { className: "input-search--wrapper" },
                            React.createElement("div", { id: "search", className: "input-search-button" }),
                            React.createElement("input", { value: searchPattern, className: "input-search--input", onChange: (evt) => setSearchPattern(evt.target.value), type: "text", placeholder: `${t('search')}` }))),
                    React.createElement(ChatRoomsSidebar, { selectedRoomId: selectedRoomId || '', searchPattern: searchPattern }),
                    React.createElement("p", { className: "text-xs text-slate-600 font-light px-1" },
                        mcli.deviceId,
                        " - ",
                        pkg.version)))),
            showRight() && (React.createElement("div", { className: "app__right ", onDragOver: (ev) => {
                    ev.preventDefault();
                }, onDrop: (ev) => {
                    ev.preventDefault();
                    let file;
                    if (ev.dataTransfer.items) {
                        // Use DataTransferItemList interface to access the file(s)
                        const maybeFile = ev.dataTransfer.items[0];
                        if (maybeFile.kind === 'file') {
                            file = maybeFile.getAsFile();
                        }
                    }
                    else if (ev.dataTransfer.files?.length > 0) {
                        file = ev.dataTransfer.files[0];
                    }
                    if (file) {
                        handleFile(file);
                    }
                } },
                React.createElement("div", { className: "app__topbar" }, selectedRoomId && (React.createElement(ChatRoomTopbar, { selectedRoomId: selectedRoomId }))),
                selectedRoomId ? (React.createElement(React.Fragment, null,
                    React.createElement(ChatMessageWindow, { reply: replyMsg }),
                    React.createElement(ChatInput, { roomId: selectedRoomId, messageToReply: messageToReply, closeReply: closeReply }))) : (React.createElement("div", { className: "flex h-full w-full bg-white" })))))));
};
export default App;
