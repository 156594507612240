import * as React from 'react';
import mcli from '../../libs/matrix';
import { useAppDispatch } from '../../hooks';
import { getBackup } from '../../libs/matrix/backup';
import { updateRestored, updateState } from '../../reducer/app';
import sdk from '../../libs/mapp/sdk';
const PreparingChat = () => {
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        getBackup().then((bk) => {
            if (bk && bk.backupInfo && bk.trustInfo.trusted_locally) {
                mcli.restoreKeyBackupWithSecretStorage(bk.backupInfo).finally(() => {
                    dispatch(updateState('ready'));
                    dispatch(updateRestored(true));
                    if (bk?.backupInfo)
                        mcli.enableKeyBackup(bk.backupInfo);
                });
            }
            else {
                sdk.Configuration.get().then((res) => {
                    if (res.chatEncryptionOption === 0 ||
                        res.chatEncryptionOption === 2) {
                        dispatch(updateState('no_restore'));
                    }
                    else {
                        dispatch(updateState('ready'));
                    }
                });
            }
        });
    }, []);
    return React.createElement(React.Fragment, null);
};
export default PreparingChat;
