import { MatrixEventEvent } from 'matrix-js-sdk';
import moment from 'moment';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import { createTEvent } from '../../libs/matrix/event';
import { openChatMessageDeleteModal, openShareMessageModal, } from '../../reducer/app';
import { selectEvent, selectRoom, selectMatrixEvent, updateEvent, } from '../../reducer/matrix';
import Switch from './Switch';
import CircleInfo from '../Icons/CircleInfo';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
const EVENTS = [
    MatrixEventEvent.LocalEventIdReplaced,
    MatrixEventEvent.RelationsCreated,
    MatrixEventEvent.Replaced,
    MatrixEventEvent.Status,
    MatrixEventEvent.VisibilityChange,
];
const ChatMessage = ({ id, callback, reply, isDirect }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const storeEvent = useAppSelector((state) => selectEvent(state, id));
    const matrixEvent = useAppSelector((state) => selectMatrixEvent(state, id));
    const user = mcli.getUser(storeEvent?.sender || '');
    let message = storeEvent?.content?.body;
    const me = storeEvent?.sender === mcli.getUserId();
    const content = storeEvent?.content;
    const sender = storeEvent?.sender || '';
    const eventType = storeEvent?.type || '';
    const roomId = storeEvent?.roomId || '';
    const eventId = storeEvent?.id || '';
    const room = useAppSelector((state) => selectRoom(state, roomId));
    let avatar = '';
    React.useEffect(() => {
        if (!matrixEvent || !storeEvent)
            return;
        matrixEvent?.setMaxListeners(255);
        EVENTS.forEach((evt) => {
            matrixEvent.on(evt, (mevent) => {
                dispatch(updateEvent({ event: createTEvent(mevent) }));
            });
        });
        matrixEvent.on(MatrixEventEvent.BeforeRedaction, (mevent) => {
            dispatch(updateEvent({ event: createTEvent(mevent, true) }));
        });
        matrixEvent.on(MatrixEventEvent.Decrypted, (mevent) => {
            dispatch(updateEvent({ event: createTEvent(mevent) }));
        });
        if (matrixEvent.isDecryptionFailure() && mcli.crypto) {
            matrixEvent.cancelAndResendKeyRequest(mcli.crypto, matrixEvent.getSender() || '');
        }
        return () => {
            EVENTS.forEach((evt) => {
                matrixEvent.removeListener(evt, (mevent) => {
                    dispatch(updateEvent({ event: createTEvent(mevent) }));
                });
            });
            matrixEvent.removeListener(MatrixEventEvent.BeforeRedaction, (mevent) => {
                dispatch(updateEvent({ event: createTEvent(mevent, true) }));
            });
            matrixEvent.removeListener(MatrixEventEvent.Decrypted, (mevent) => {
                dispatch(updateEvent({ event: createTEvent(mevent) }));
            });
        };
    }, []);
    if (!storeEvent)
        return React.createElement(React.Fragment, null);
    if ((content?.avatar_url && content?.membership === 'join') ||
        content?.name)
        return React.createElement(React.Fragment, null);
    if (!message) {
        if (content?.membership === 'join') {
            message = `${content.displayname ? content.displayname : t('deleted_user')}${t('chat_join')}`;
        }
        if (content?.membership === 'leave') {
            message = `${content.displayname ? content.displayname : t('deleted_user')}${t('chat_leave')}`;
        }
        if (content?.membership === 'ban') {
            message = `${content.displayname ? content.displayname : t('deleted_user')}${t('chat_ban')}`;
        }
        if (content?.membership === 'invite') {
            message = `${content.displayname ? content.displayname : t('deleted_user')}${t('chat_invite')}`;
        }
    }
    if (storeEvent?.isStatusEvent) {
        return (React.createElement("div", { className: "flex justify-center items-center w-full my-2" },
            React.createElement("div", { className: "border h-[1px] w-[28%]" }),
            React.createElement("div", { className: `flex justify-center text-sm text-gray-800 bg-gray-200 rounded-md p-3 min-w-fit max-w-fit mx-2` }, message),
            React.createElement("div", { className: "border h-[1px] w-[28%]" })));
    }
    if (storeEvent?.isRedacted) {
        return (React.createElement("div", null,
            React.createElement("div", { className: `flex ${me ? 'justify-end' : 'justify-start'} text-sm text-gray-400` }, t('deleted_message'))));
    }
    const storeEventTime = moment(new Date(storeEvent?.timestamp || 0));
    if (user) {
        avatar = mcli.mxcUrlToHttp(user.avatarUrl || '') || '';
    }
    return (React.createElement("div", { id: storeEvent.id },
        React.createElement("div", { className: `group flex items-end mt-2 ${me && 'justify-end'} ` },
            React.createElement("div", { className: `flex flex-col space-y-2 text-xs max-w-xl mx-2 ${me ? 'order-1 items-end' : 'order-2 items-start'}` },
                React.createElement("div", { className: `flex flex-col flex-nowrap` },
                    React.createElement("div", { className: "flex justify-end items-center text-[#6C7A98]" },
                        React.createElement("div", { className: "flex items-center justify-between opacity-0 group-hover:opacity-100 ml-2" },
                            me && (React.createElement("div", { onClick: () => {
                                    dispatch(openChatMessageDeleteModal({
                                        roomId,
                                        eventId: storeEvent.id,
                                    }));
                                } },
                                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 448 512", className: "w-3 h-3 fill-gray-300 hover:fill-red-500 cursor-pointer mr-2", "data-type": 'modal-btn' },
                                    React.createElement("path", { d: "M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" })))),
                            React.createElement("div", { onClick: () => {
                                    dispatch(openShareMessageModal({
                                        roomId,
                                        storeEvent: storeEvent,
                                    }));
                                } },
                                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", className: "w-3 h-3 fill-gray-300 hover:fill-gray-400 cursor-pointer" },
                                    React.createElement("path", { d: "M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" }))),
                            reply &&
                                content?.msgtype !== 'm.bad.encrypted' && (React.createElement("div", { className: "ml-2 cursor-pointer", onClick: () => reply({ ...content, user }, sender, roomId, eventType, eventId, storeEvent.threadRootId, storeEvent.relation, storeEvent?.replyEvent) },
                                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", className: "w-3 h-3 fill-gray-300 hover:fill-gray-400 cursor-pointer" },
                                    React.createElement("path", { d: "M205 34.8c11.5 5.1 19 16.6 19 29.2v64H336c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96H224v64c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4z" }))))),
                        content?.msgtype === 'm.bad.encrypted' && (React.createElement("div", { className: "h-4 w-8" },
                            React.createElement(CircleInfo, { className: "w-4 h-4 cursor-pointer fill-gray-400 message-encrypt" }),
                            React.createElement(Tooltip, { anchorSelect: ".message-encrypt", place: "top", className: "message-encrypt-tooltip" }, t('missing_key'))))),
                    content && (React.createElement("div", { className: `flex ${me && 'justify-end'}` },
                        !me && (React.createElement("div", { className: "rounded-full flex items-end justify-center mr-2" }, avatar ? (React.createElement("img", { src: avatar, alt: "User Avatar", className: "w-8 h-8 rounded-full" })) : (React.createElement("div", { className: "w-8 h-8 rounded-full bg-primary-full fill-white flex items-center justify-center" },
                            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 448 512", className: "w-4 h-4" },
                                React.createElement("path", { d: "M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" })))))),
                        React.createElement("span", { className: `relative p-2 max-w-md rounded-lg inline-block ${me
                                ? 'bg-primary-bubble rounded-br-none'
                                : 'bg-sidebar rounded-bl-none'}
            						` },
                            !me && !room?.isDirect && (React.createElement("span", { className: "py-1 flex items-center font-semibold" }, user?.rawDisplayName)),
                            content['a.forwarded'] && (React.createElement("div", { className: "flex items-center text-gray-500 fill-gray-500" },
                                React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", className: "w-2 h-2 mr-3" },
                                    React.createElement("path", { d: "M374.6 374.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L402.7 256H96c-17.7 0-32 14.3-32 32V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V288c0-53 43-96 96-96H402.7l-73.4-73.4c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3l-128 128z" })),
                                React.createElement("span", { className: "text-xs" }, t('forwarded')))),
                            React.createElement(Switch, { content: content, message: message, me: me, roomId: roomId, replyTo: storeEvent.replyTo, callback: callback }),
                            React.createElement("div", { className: "flex flex-row flex-nowrap mt-2 justify-end" },
                                React.createElement("span", { className: `${!me ? 'mx-1' : 'mr-2'} text-right` }, storeEventTime.isBefore(new Date(), 'day')
                                    ? storeEventTime.format('DD.MMM')
                                    : storeEventTime.format('HH:mm')),
                                me && isDirect ? (storeEvent.isSending ? (React.createElement("div", { className: "" },
                                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", className: "w-3 h-3" },
                                        React.createElement("path", { d: "M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" })))) : (React.createElement("span", { className: "mr-1" }, storeEvent.readState >
                                    1 ? (React.createElement("img", { src: "/sent1.svg", className: "h-4 w-4" })) : (React.createElement("img", { src: "/read1.svg", className: "h-4 w-4", alt: "read" }))))) : (React.createElement(React.Fragment, null)))))),
                    process.env.NODE_ENV === 'development' && (React.createElement("span", { className: "text-slate-400 text-right", onClick: () => console.debug('DEBUG', {
                            eventId,
                            msgType: eventType,
                            content,
                        }) }, "details")))))));
};
export default ChatMessage;
