import * as React from 'react';
import sdk from './libs/mapp/sdk';
import mcli, { auth } from './libs/matrix';
import { useLottie } from 'lottie-react';
import { ClientEvent } from 'matrix-js-sdk/lib/client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import loadingAnimation from './assets/animations/loading.json';
import App from './components/App';
import BackupRestore from './components/BackupRestore';
import PreparingChat from './components/PreparingChat';
import { useAppDispatch, useAppSelector } from './hooks';
import './i18n';
import AppLayout from './layouts/AppLayout';
import store from './libs/matrix/store';
import { selectState, updateState, updateSyncState } from './reducer/app';
import { getRoomsAsync } from './reducer/matrix';
import { updateMatrixProfile } from './reducer/user';
import { LogoutButton } from './components/Logout/LogoutButton';
mcli.setMaxListeners(500);
const AppState = () => {
    const appState = useAppSelector(selectState);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    moment.locale(navigator.language);
    const animationOptions = {
        animationData: loadingAnimation,
        loop: true,
    };
    const { View: LottieView } = useLottie(animationOptions);
    React.useEffect(() => {
        if (appState === 'new') {
            // @ts-ignore
            mcli.on(ClientEvent.Sync, statePreparedCallback);
        }
        return () => {
            mcli.removeListener(ClientEvent.Sync, statePreparedCallback);
        };
    }, [appState]);
    const statePreparedCallback = (state, lastState, data) => {
        dispatch(updateSyncState({ state, lastState, data }));
        if (state === 'PREPARED') {
            dispatch(updateMatrixProfile());
            dispatch(getRoomsAsync()).finally(() => {
                dispatch(updateState('prepared'));
            });
        }
        if (state === 'ERROR') {
            if (data?.error.message.includes('Invalid access token')) {
                sdk.Auth.logout();
                auth.logout().finally(() => {
                    window.location.reload();
                });
            }
        }
    };
    if (appState === 'prepared') {
        return React.createElement(PreparingChat, null);
    }
    if (appState === 'no_restore' && store.client.getCrypto()) {
        return React.createElement(BackupRestore, null);
    }
    if (appState === 'ready') {
        return (React.createElement(AppLayout, null,
            React.createElement(App, null)));
    }
    return (React.createElement("div", { className: "h-full w-full flex flex-col justify-center items-center" },
        React.createElement("div", { className: "w-1/3 flex flex-col justify-center items-center" },
            LottieView,
            React.createElement("div", null, t('loading_chat'))),
        React.createElement(LogoutButton, null)));
};
export default AppState;
