import encrypt from 'matrix-encrypt-attachment';
import store from './store';
export const uploadFileEncrypted = async (file) => {
    const buffer = await readFileAsArrayBuffer(file);
    const encryptResult = await encrypt.encryptAttachment(buffer);
    const blob = new Blob([encryptResult.data]);
    const { content_uri: url } = await store.client.uploadContent(blob, {
        includeFilename: false,
    });
    return {
        file: {
            url,
            ...encryptResult.info,
        }
    };
};
export const uploadFile = async (file) => {
    const { content_uri: url } = await store.client.uploadContent(file, {
        includeFilename: true,
    });
    return {
        file: {
            url,
        },
    };
};
export const readFileAsArrayBuffer = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
};
