import { MatrixEvent } from 'matrix-js-sdk';
import * as React from 'react';
import sanitizeHtml from 'sanitize-html';
import mcli from '../../libs/matrix';
import store from '../../libs/matrix/store';
import Switch from './Switch';
const ALLOWED_TAGS = [
    'font',
    'del',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'ul',
    'ol',
    'sup',
    'sub',
    'blockquote',
    'li',
    'b',
    'i',
    'u',
    'strong',
    'em',
    'strike',
    'code',
    'hr',
    'br',
    'div',
    'table',
    'thead',
    'tbody',
    'tr',
    'th',
    'td',
    'caption',
    'pre',
    'span',
    'img',
    'details',
    'summary',
];
const PERMITTED_URL_SCHEMES = [
    'ftp',
    'geo',
    'http',
    'https',
    'im',
    'irc',
    'ircs',
    'mailto',
    'news',
    'nntp',
    'openpgp4fpr',
    'sip',
    'sftp',
    'ssh',
    'tel',
    'urn',
    'webcal',
];
const Quote = ({ replyTo, roomId, me, callback, }) => {
    const [name, setName] = React.useState('');
    const [replyContent, setReplyContent] = React.useState(null);
    const found = store.events.find((evt) => evt.eventId === replyTo);
    React.useEffect(() => {
        const fetchEvent = mcli.fetchRoomEvent(roomId, replyTo);
        const copyData = (evt) => {
            const _ctx = evt.getContent();
            setReplyContent(_ctx);
            const user = mcli.getUser(evt.getSender() || "");
            setName(user?.displayName || "");
        };
        if (found?.event) {
            copyData(found.event);
        }
        else {
            fetchEvent.then((mEvent) => {
                const mvent = new MatrixEvent(mEvent);
                mcli.decryptEventIfNeeded(mvent).then(() => {
                    store.events.push({
                        eventId: mvent.getId() || '',
                        event: mvent,
                    });
                    copyData(mvent);
                });
            });
        }
    }, [replyTo, roomId, me]);
    function cleanHtml(body) {
        return sanitizeHtml(body, {
            allowedTags: ALLOWED_TAGS, // false means allow everything
            allowedAttributes: false,
            // we somehow can't allow all schemes, so we allow all that we
            // know of and mxc (for img tags)
            allowedSchemes: [...PERMITTED_URL_SCHEMES, 'mxc'],
            exclusiveFilter: (frame) => frame.tag === 'mx-reply',
        });
    }
    const sanatizedHtml = cleanHtml(replyContent?.formatted_body || replyContent?.body);
    const msg = sanatizedHtml.replace(/<blockquote(.*?)<\/blockquote>|((.|\n)*)<\/blockquote>/g, '');
    return (React.createElement("div", { className: `quote ${me && 'quote-me'}` },
        React.createElement("div", { className: "flex flex-col" },
            React.createElement("span", { className: "text-xs" }, name),
            replyContent && (React.createElement("span", null,
                React.createElement(Switch, { content: replyContent, message: msg, me: me, replyTo: null, isReply: true, callback: callback, roomId: roomId }))))));
};
export default React.memo(Quote);
