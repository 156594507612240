import React from "react";
import sdk from "../../libs/mapp/sdk";
import { auth } from "../../libs/matrix";
export function LogoutButton() {
    const logout = async () => {
        await sdk.Auth.logout();
        await auth.logout();
        return window.location.href = "/";
    };
    // tailwind button for logout simple
    return React.createElement("button", { className: "py-2 px-4 rounded", onClick: logout },
        React.createElement("span", { className: "underline" }, "Logout"));
}
