import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getConfigurationAsync, selectChatEnabled, } from '../../reducer/app';
import FirstLoginActivation from '../../components/FirstLogin/FirstLoginActivation';
const FirstLogin = () => {
    const dispatch = useAppDispatch();
    const chatEnabled = useAppSelector(selectChatEnabled);
    React.useEffect(() => {
        if (chatEnabled)
            window.location.href = '/app';
        dispatch(getConfigurationAsync());
    }, []);
    const dummyElements = () => {
        let array = [];
        for (let i = 0; i < 10; i++) {
            array.push(React.createElement("div", { className: "ListItem animate-pulse", key: i },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("div", { className: "rounded-full bg-slate-300 w-12 h-12 mr-3" }),
                    React.createElement("div", { className: "flex flex-col" },
                        React.createElement("div", { className: "bg-slate-300 w-40 h-3 mb-4 rounded-lg" }),
                        React.createElement("div", { className: "bg-slate-300 w-10 h-3 rounded-lg" })))));
        }
        return array.map((element) => element);
    };
    return (React.createElement("div", { className: "app" },
        React.createElement("div", { className: "app__left" },
            React.createElement("div", { className: "app__topbar" },
                React.createElement("div", { className: "rounded-full bg-slate-300 w-12 h-12 mr-3" })),
            dummyElements()),
        React.createElement(FirstLoginActivation, null)));
};
export default FirstLogin;
