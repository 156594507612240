import * as React from 'react';
import { auth } from '../../libs/matrix';
import { saveLocal } from '../../libs/browser/storage';
import { useAppDispatch } from '../../hooks';
import { updateState } from '../../reducer/app';
const LoginSucess = () => {
    const dispatch = useAppDispatch();
    const getLoginToken = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('loginToken') || '';
    };
    const ssoLoginRoute = `${process.env.MATRIX_URL}/_matrix/client/r0/login/sso/redirect?redirectUrl=${window.location.origin}/login-success`;
    React.useEffect(() => {
        const queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token');
        if (token) {
            const a = document.createElement('a');
            a.href = ssoLoginRoute;
            a.click();
        }
        if (localStorage) {
            ssoLogin();
        }
    }, []);
    const ssoLogin = () => {
        if (!getLoginToken())
            return;
        auth.loginwithsso(getLoginToken()).then((res) => {
            res.json().then((json) => {
                const loginResponse = {
                    accessToken: json.access_token,
                    userId: json.user_id,
                    deviceId: json.device_id,
                    homeServer: json.home_server,
                };
                saveLocal('chat', loginResponse);
                auth.loginWithToken(loginResponse)
                    .then(() => {
                    dispatch(updateState('new'));
                    window.location.href = '/app';
                })
                    .catch((err) => console.error(err));
            });
        });
    };
    return React.createElement(React.Fragment, null);
};
export default LoginSucess;
