import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { sendMedia } from '../../libs/matrix/message';
import { hideActiveModal, selectUploadModal } from '../../reducer/app';
import { selectSelectedRoom } from '../../reducer/matrix';
import ModalSkelleton from '../ModalSkelleton';
const UploadModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const roomId = useAppSelector(selectSelectedRoom);
    const data = useAppSelector(selectUploadModal);
    const [uploadImageUrl, setUploadImageUrl] = React.useState();
    const [sending, setSending] = React.useState(false);
    React.useEffect(() => {
        if (data?.files.length > 0) {
            setUploadImageUrl(data?.files[0]);
        }
    }, [data, roomId]);
    const sendImage = async (id) => {
        if (!uploadImageUrl)
            return;
        setSending(true);
        let blob = await fetch(uploadImageUrl).then((r) => r.blob());
        return sendMedia(id, 'm.image', blob, data?.fileName).finally(() => {
            dispatch(hideActiveModal());
            setSending(false);
        });
    };
    if (!roomId)
        return React.createElement(React.Fragment, null);
    return (React.createElement(ModalSkelleton, null,
        React.createElement("div", { className: "p-4" },
            React.createElement("div", { className: "flex items-center justify-between" },
                React.createElement("span", { className: "font-semibold text-lg" }, t('upload_picture')),
                React.createElement("div", { onClick: () => dispatch(hideActiveModal()) },
                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 384 512", className: "w-5 h-5 cursor-pointer fill-gray-200 hover:fill-gray-250" },
                        React.createElement("path", { d: "M378.4 440.6c8.531 10.16 7.203 25.28-2.938 33.81C370.9 478.2 365.5 480 360 480c-6.844 0-13.64-2.906-18.39-8.562L192 293.3l-149.6 178.1C37.63 477.1 30.83 480 23.98 480c-5.453 0-10.92-1.844-15.42-5.625c-10.14-8.531-11.47-23.66-2.938-33.81L160.7 256L5.625 71.44C-2.906 61.28-1.578 46.16 8.563 37.63C18.69 29.08 33.84 30.39 42.38 40.56L192 218.7l149.6-178.1c8.547-10.17 23.67-11.47 33.81-2.938s11.47 23.66 2.938 33.81L223.3 256L378.4 440.6z" })))),
            React.createElement("div", { className: "flex items-center justify-center" }, uploadImageUrl && (React.createElement("img", { className: "p-3", src: uploadImageUrl }))),
            React.createElement("div", null,
                React.createElement("button", { className: "mapp-button mapp-button-primary", disabled: sending, onClick: () => sendImage(roomId) }, t('upload_picture'))))));
};
export default UploadModal;
