import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import { getLastMessage } from '../../libs/matrix/message';
import { getRoomAsync, selectRooms, updateSelectedRoom, } from '../../reducer/matrix';
import ListItem from './ListItem';
const orderLatest = (a, b) => {
    const ea = a.events;
    const eb = b.events;
    if (ea.length === 0)
        return -1;
    if (eb.length === 0)
        return 0;
    let lastA = getLastMessage(ea);
    let lastB = getLastMessage(eb);
    if (!lastA)
        return 1;
    if (!lastB)
        return -1;
    if (lastA.timestamp > lastB.timestamp)
        return -1;
    return 1;
};
const ChatRoomsSidebar = ({ selectedRoomId, searchPattern, }) => {
    const dispatch = useAppDispatch();
    const rooms = useAppSelector(selectRooms);
    const isFavourite = (roomId) => {
        const tags = mcli.getRoom(roomId)?.getAccountData('m.tag');
        try {
            // mcli.getRoomTags <- would also be possible, but is async and we have already account data...
            return tags?.event?.content?.tags['m.favourite']['order'];
        }
        catch {
            return false;
        }
    };
    const handlePin = (roomId) => {
        const room = mcli.getRoom(roomId);
        if (!room)
            return;
        if (isFavourite(roomId)) {
            mcli.deleteRoomTag(roomId, 'm.favourite');
            return;
        }
        mcli.setRoomTag(roomId, 'm.favourite', { order: 1 });
    };
    const searchedRooms = rooms.filter((r) => r.name.toLowerCase().includes(searchPattern?.toLowerCase() || ''));
    const favourites = searchedRooms
        .filter((r) => isFavourite(r.id))
        .sort(orderLatest);
    const lowprio = searchedRooms
        .filter((r) => !isFavourite(r.id))
        .sort(orderLatest);
    return (React.createElement("div", { className: 'Sidebar scrollbar' },
        React.createElement("ul", { className: `sidebar-entries ${favourites.length ? 'border-b2' : ''}` }, favourites.map((room) => (React.createElement(ListItem, { key: room.id, roomId: room.id, onSelect: () => {
                dispatch(updateSelectedRoom(room.id));
                dispatch(getRoomAsync(room.id));
            }, isSelected: selectedRoomId === room.id, onPin: handlePin, isPinned: true })))),
        React.createElement("ul", { className: "sidebar-entries" }, lowprio.map((room) => (React.createElement(ListItem, { key: room.id, roomId: room.id, onSelect: () => {
                dispatch(updateSelectedRoom(room.id));
                dispatch(getRoomAsync(room.id));
            }, isSelected: selectedRoomId === room.id, onPin: handlePin, isPinned: false }))))));
};
export default ChatRoomsSidebar;
