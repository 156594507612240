import { saveLocal } from '../../../src/libs/browser/storage';
import { ClientPrefix, IndexedDBCryptoStore } from 'matrix-js-sdk';
import * as sdk from 'matrix-js-sdk';
import { crossSigningCallbacks } from './security';
import store from './store';
let CUSTOM_HOMESERVER;
// @ts-ignore
ClientPrefix.V3 = '/_matrix/client/unstable';
const loginWithToken = async (loginResponse) => {
    const url = CUSTOM_HOMESERVER || loginResponse.homeServer || store.client.baseUrl;
    {
        store.client = sdk.createClient({
            ...{
                baseUrl: 'https://' + url,
                cryptoStore: new IndexedDBCryptoStore(indexedDB, 'mapp-chat'),
                timelineSupport: true,
                useAuthorizationHeader: true,
                verificationMethods: ['m.sas.v1'],
                cryptoCallbacks: crossSigningCallbacks,
            },
            ...loginResponse,
        });
        await store.client.initCrypto();
        const crypto = store.client.getCrypto();
        // await tryToUnlockSecretStorageWithDehydrationKey(store.client);
        await store.client.startClient({ initialSyncLimit: 12 });
        const userId = store.client.getUserId();
        if (userId === null)
            throw new Error('UserID is null.');
        if (crypto) {
            crypto.getUserDeviceInfo([userId]);
            crypto.globalBlacklistUnverifiedDevices = false;
            store.client.setGlobalErrorOnUnknownDevices(false);
        }
        return store.client.isLoggedIn();
    }
};
const login = async (username, password, url, token) => {
    CUSTOM_HOMESERVER = url;
    if (CUSTOM_HOMESERVER)
        store.client.baseUrl = CUSTOM_HOMESERVER;
    store.client = sdk.createClient({
        ...{
            baseUrl: url || store.client.baseUrl,
            cryptoStore: new IndexedDBCryptoStore(indexedDB, 'mapp-chat'),
            timelineSupport: true,
            useAuthorizationHeader: true,
            verificationMethods: ['m.sas.v1'],
            cryptoCallbacks: crossSigningCallbacks,
        },
    });
    let response;
    if (token) {
        response = await store.client.login('m.login.token', { token });
    }
    else {
        response = await store.client.login('m.login.password', {
            identifier: {
                address: username,
                medium: 'email',
                type: 'm.id.thirdparty',
            },
            password,
        });
    }
    const loginResponse = {
        accessToken: response.access_token,
        userId: response.user_id,
        deviceId: response.device_id,
        homeServer: response.home_server,
    };
    saveLocal('chat', loginResponse);
    return loginResponse;
};
export const logout = async () => {
    try {
        await store.client.logout();
    }
    catch (e) {
        console.error(e);
    }
    indexedDB.deleteDatabase('mapp-chat');
    localStorage.clear();
};
const isLoggedIn = async () => {
    try {
        const isLoggedInCheck = store.client.isLoggedIn();
        if (!isLoggedInCheck) {
            await logout();
        }
        return isLoggedInCheck;
    }
    catch (e) {
        console.error(e);
        return false;
    }
};
const loginwithsso = async (token) => {
    const res = fetch(`${process.env.MATRIX_URL}/_matrix/client/r0/login`, {
        method: 'POST',
        body: JSON.stringify({
            type: 'm.login.token',
            token,
            initial_device_display_name: 'My device',
        }),
    });
    return res;
};
export default {
    login,
    loginWithToken,
    logout,
    isLoggedIn,
    loginwithsso,
};
