import * as React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import sdk from '../../libs/mapp/sdk';
import PasswordChecker from '../PasswordCheck/PasswordCheck';
const LoginUpdate = ({ reset, oldPassword }) => {
    const [password, setPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [isDisabled, setIsDisabled] = React.useState(false);
    const { t } = useTranslation();
    const updatePassword = (e) => {
        e.preventDefault();
        if (password !== newPassword) {
            return toast.error('Passwords not matching');
        }
        sdk.Auth.update(
        // @ts-ignore
        localStorage.getItem('username'), oldPassword, newPassword).then(() => {
            reset();
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "my-8" },
            React.createElement("h1", null, t('update_password'))),
        React.createElement("form", { onSubmit: updatePassword },
            React.createElement("div", { className: "login-item" },
                React.createElement("label", { htmlFor: "password" }, t('new_password')),
                React.createElement("input", { type: 'password', name: "password", id: "password", placeholder: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022", onChange: (event) => setPassword(event.target.value), className: "login-input", minLength: 8, required: true })),
            React.createElement("div", { className: "login-item" },
                React.createElement("label", { htmlFor: "password2" }, t('repeat_password')),
                React.createElement("input", { type: 'password', name: "password", id: "password2", placeholder: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022", onChange: (event) => setNewPassword(event.target.value), className: "login-input", minLength: 8, required: true })),
            React.createElement(PasswordChecker, { password: password, passwordRepeat: newPassword, disableFn: setIsDisabled }),
            React.createElement("div", { className: "login-item mt-12" },
                React.createElement("button", { type: "submit", className: "mapp-button mapp-button-primary w-full", disabled: isDisabled || !password || !newPassword }, t('update_password'))),
            React.createElement("div", { className: "login-item login-password-reset", onClick: reset }, t('abort')))));
};
export default LoginUpdate;
