import * as React from 'react';
const isOnlyEssential = (services) => {
    for (const svc of services) {
        if (!svc.isEssential)
            return false;
    }
    return true;
};
const COOKIE_LOCAL_STORAGE_NAME = 'cb_cookies';
const CookieBanner = ({ labels, services, }) => {
    const [cookiesEnabled, setCookiesEnabled] = React.useState(true);
    const [clickedInfo, setClickedInfo] = React.useState(false);
    React.useEffect(() => {
        const cookie = localStorage.getItem(COOKIE_LOCAL_STORAGE_NAME);
        setCookiesEnabled(!!cookie);
    });
    if (cookiesEnabled)
        return React.createElement(React.Fragment, null);
    return (React.createElement("div", { className: 'cookiebanner' },
        React.createElement("div", { className: 'cookie-content' }, !clickedInfo ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'cookie-header' }, labels.title),
            React.createElement("div", { className: 'cookie-body' },
                React.createElement("div", null, labels.description),
                React.createElement("div", { className: 'cookie-checkboxes' }, services.map((svc, index) => (React.createElement("div", { key: 'cb_' + index, className: "mapp-checkbox" },
                    React.createElement("input", { type: "checkbox", disabled: svc.isEssential, checked: svc.isEssential }),
                    React.createElement("label", { htmlFor: index.toString() }, svc.name))))),
                React.createElement("div", { className: 'cookie-buttons' },
                    React.createElement("button", { className: "mapp-button mapp-button-primary w-full mb-2", onClick: () => {
                            localStorage.setItem(COOKIE_LOCAL_STORAGE_NAME, '' + true);
                            setCookiesEnabled(true);
                        } }, labels.acceptBtn),
                    !isOnlyEssential(services) && (React.createElement("button", { className: "" }, labels.denyBtn)),
                    React.createElement("button", { className: "mapp-button mapp-button-secondary w-full", onClick: () => setClickedInfo(true) }, labels.infoBtn)),
                React.createElement("div", { className: 'cookie-footer' },
                    React.createElement("a", { href: "https://web.staffice.de/privacy" }, labels.policy))))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'cookie-header' }, labels.infoBtn),
            services.map((svc) => {
                return (React.createElement("div", { key: svc.name },
                    React.createElement("h5", null, svc.name),
                    React.createElement("p", null, svc.description),
                    React.createElement("table", { className: 'cookie-table' },
                        React.createElement("tr", null,
                            React.createElement("th", null, "Cookie"),
                            React.createElement("th", null, svc.descriptionHeader),
                            React.createElement("th", null, svc.vendorHeader),
                            React.createElement("th", null, svc.timeHeader)),
                        svc.cookies.map((cookie, index) => {
                            return (React.createElement("tr", { key: 'cookie_' + index },
                                React.createElement("td", null, cookie.cookieName),
                                React.createElement("td", null, cookie.purpose),
                                React.createElement("td", null, cookie.vendor),
                                React.createElement("td", null, cookie.cookieExpireText)));
                        }))));
            }),
            React.createElement("button", { type: "button", className: "mapp-button mapp-button-secondary", value: "Back", onClick: () => setClickedInfo(false) }, labels.backBtn || 'Back'))))));
};
export default CookieBanner;
