import * as React from 'react';
import sdk from '../../libs/mapp/sdk';
import { auth } from '../../libs/matrix';
import { selectState, selectSyncState, updateChatEnabled, } from '../../reducer/app';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router-dom';
const StafficeAuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const appState = useAppSelector(selectState);
    const syncState = useAppSelector(selectSyncState);
    const dispatch = useAppDispatch();
    const [isLoggedIn, setIsLoggedIn] = React.useState(null);
    React.useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const promises = await Promise.allSettled([
                    checkApiValidAccess(),
                    checkMatrixValidAccess(),
                ]);
                const someError = promises.some((r) => {
                    return r.status !== 'fulfilled' || r.value === false;
                });
                if (someError) {
                    await sdk.Auth.logout();
                    await auth.logout();
                    return navigate('/login');
                }
                setIsLoggedIn(!someError);
                navigate('/chat');
            }
            catch {
                setIsLoggedIn(false);
            }
        };
        checkAuthStatus();
    }, [appState]);
    const checkApiValidAccess = async () => {
        const { status, response } = await sdk.Auth.isLoggedIn();
        const apiIsError = status && status > 399;
        if (status === 200) {
            const res = await response.json();
            dispatch(updateChatEnabled(res.data?.chatEnabled));
        }
        return !apiIsError;
    };
    const checkMatrixValidAccess = () => {
        const check = syncState.data?.error?.errcode !== 'M_UNKNOWN_TOKEN';
        const accessToken = auth.isLoggedIn();
        return check && accessToken;
    };
    if (!isLoggedIn || isLoggedIn === null)
        return React.createElement(React.Fragment, null);
    return React.createElement(React.Fragment, null, children);
};
export default StafficeAuthProvider;
