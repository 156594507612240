import * as React from 'react';
import mcli from '../../libs/matrix';
import { getDecryptedVoiceMessage } from '../../libs/matrix/message';
const Audio = ({ content, callback }) => {
    const isDecrypted = 'file' in content;
    const fileUrl = isDecrypted ? content.file.url : content.url;
    const [audio, setAudio] = React.useState();
    React.useEffect(() => {
        if (!isDecrypted) {
            callback();
            return;
        }
        ;
        getDecryptedVoiceMessage(content.file).then((res) => {
            setAudio(res);
        });
    }, []);
    // Loader Skeleton
    if (isDecrypted && !audio)
        return React.createElement(React.Fragment, null);
    return (React.createElement("audio", { controls: true }, isDecrypted ? (React.createElement("source", { src: audio })) : (React.createElement("source", { src: mcli.mxcUrlToHttp(fileUrl) || '' }))));
};
export default Audio;
