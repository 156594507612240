import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../hooks';
import sdk from '../../libs/mapp/sdk';
import { auth } from '../../libs/matrix';
import { getConfigurationAsync } from '../../reducer/app';
import CookieBanner from '../../components/CookieBanner';
import { useNavigate } from 'react-router-dom';
import LoginUpdate from '../../components/LoginUpdate/LoginUpdate';
const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState();
    const [accountError, setAccountError] = React.useState(false);
    const [updatePassword, setUpdatePassword] = React.useState(false);
    const [oldPassword, setOldPassword] = React.useState('');
    const handleSubmit = (evt) => {
        evt.preventDefault();
        sdk.Auth.login(email, password)
            .then((res) => {
            if (res.statusCode === 401) {
                setError(true);
                return;
            }
            if (res.statusCode === 404 || res.statusCode === 500) {
                setAccountError(true);
                return;
            }
            if (res.statusCode === 423) {
                localStorage.setItem('username', email);
                setOldPassword(password);
                setUpdatePassword(true);
                return;
            }
            dispatch(getConfigurationAsync()).then((res) => {
                sdk.User.get().then((res) => {
                    if (res.data.chatEnabled === false) {
                        return navigate('/first-login');
                    }
                    ;
                    auth.login(email, password, sdk.Store.Configuration.get()?.matrixServer)
                        .then((loggedIn) => {
                        if (!loggedIn) {
                            setError(true);
                            return;
                        }
                        // @ts-ignore
                        window.location.href = '/chat';
                    })
                        .catch((e) => {
                        setError(true);
                    });
                });
            });
        })
            .catch((e) => setError(true));
    };
    const loginURL = `${process.env.KEYCLOAK_CLIENT_URL}/realms/${process.env.KEYCLOAK_CLIENT_REALM}/protocol/openid-connect/auth?client_id=${process.env.KEYCLOAK_CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${process.env.KEYCLOAK_CLIENT_REDIRECT_URL}`;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex justify-evenly w-screen' },
            React.createElement("div", { className: 'login-container' },
                React.createElement("div", { className: 'login-form' },
                    React.createElement("div", { className: 'login-staffice-icon' },
                        React.createElement("img", { src: '/Staffice_Logo.svg' })),
                    React.createElement("div", { className: 'flex items-center justify-center mb-4 text-gray-250' },
                        React.createElement("h3", null, "Chat")),
                    updatePassword && (React.createElement(LoginUpdate, { reset: () => setUpdatePassword(!updatePassword), oldPassword: oldPassword })),
                    !updatePassword && (React.createElement("form", { onSubmit: handleSubmit },
                        process.env.NORMAL_LOGIN === 'true' && (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'login-item' },
                                React.createElement("label", { htmlFor: 'email' }, t('login_form_placeholder_username')),
                                React.createElement("input", { value: email, onChange: (e) => setEmail(e.target.value), type: 'email', id: 'email', placeholder: 'max.mustermann@mapp.de', className: 'login-input' })),
                            React.createElement("br", null),
                            React.createElement("div", { className: 'login-item' },
                                React.createElement("label", { htmlFor: 'password' }, t('login_form_placeholder_password')),
                                React.createElement("input", { id: 'password', value: password, onChange: (e) => setPassword(e.target.value), type: 'password', placeholder: '****', className: 'login-input' })),
                            React.createElement("br", null),
                            error && (React.createElement("div", { className: 'login-item flex items-center justify-center text-red-400' }, t('login_failed_userpassword'))),
                            accountError && (React.createElement("div", { className: 'login-item flex items-center justify-center text-red-400' }, t('login_failed_userpassword'))),
                            React.createElement("br", null))),
                        process.env.LOGIN_SSO === 'true' && (React.createElement("a", { href: loginURL, className: 'mapp-button no-underline text-primary-full' }, t('sign_in_with_sso'))),
                        process.env.NORMAL_LOGIN === 'true' && (React.createElement("div", { className: 'login-item mt-12' },
                            React.createElement("button", { type: 'submit', className: 'mapp-button mapp-button-primary w-full' }, t('login'))))))),
                React.createElement("div", { className: 'login-bg w-full' },
                    React.createElement("img", { src: '/login-background-1.jpg' })))),
        React.createElement(CookieBanner, { labels: {
                title: t('privacy_policy'),
                description: t('privacy_text'),
                acceptBtn: t('terms_accept'),
                denyBtn: t('terms_deny'),
                infoBtn: 'Information',
                backBtn: t('cookie_back') || '',
                policy: t('privacy_policy'),
            }, services: [
                {
                    name: t('essential'),
                    isEssential: true,
                    descriptionHeader: t('description'),
                    vendorHeader: t('vendor'),
                    timeHeader: t('time'),
                    cookies: [
                        {
                            name: 'Authentication Cookie',
                            vendor: t('cookie_vendor'),
                            vendorLink: 'https://www.staffice.app/datenschutzerklaerung/',
                            purpose: t('cookie_purpose'),
                            cookieName: t('cookie_name'),
                            cookieExpireText: t('cookie_time'),
                        },
                    ],
                },
            ] })));
};
export default Login;
