import * as React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
export default function TypingIndicator({ typingUsers }) {
    return (React.createElement("div", { className: "chat-is-typing" },
        typingUsers.map((user, index) => (React.createElement("div", { key: user.userId },
            user.name,
            index > 0 ? ', ' : ' '))),
        React.createElement("div", { className: "chat-is-typing__indicator" },
            React.createElement(PulseLoader, { color: '#FFFFFF', speedMultiplier: 0.5, size: 4 }))));
}
