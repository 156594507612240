import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducer/app';
import userReducer from './reducer/user';
import matrixReducer from './reducer/matrix';
export const store = configureStore({
    reducer: {
        app: appReducer,
        user: userReducer,
        matrix: matrixReducer,
    },
    devTools: process.env.NODE_ENV === 'development',
});
