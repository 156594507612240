import * as React from 'react';
const BadEncryptText = ({ me, children, isReply, }) => {
    return (React.createElement("span", { className: `px-4 py-2 rounded-lg inline-block rounded-bl-none ${!isReply
            ? me
                ? 'border-background-normal border border-solid text-background-normal'
                : 'border-background-dark border border-solid text-background-dark'
            : 'bg-transparent'}` },
        React.createElement("div", { className: "flex flex-col" }, children)));
};
export default BadEncryptText;
