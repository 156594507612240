import * as React from 'react';
import { useAppDispatch } from '../../hooks';
import { updateRestored, updateState } from '../../reducer/app';
import Restore from './Restore';
import { useTranslation } from 'react-i18next';
import Cross from '../Icons/Cross';
const BackupRestore = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const clicked = false;
    const [passphrase, setPassphrase] = React.useState('');
    const [error, setError] = React.useState('');
    const [restoring, setRestoring] = React.useState(false);
    const callback = (error, success) => {
        if (!error && success) {
            dispatch(updateState('ready'));
            dispatch(updateRestored(true));
            return;
        }
        setRestoring(false);
        if (error?.message.includes('Error decrypting secret m.megolm_backup.v1: bad MAC')) {
            setError(`${t('restore_error')}`);
            return;
        }
        setError(error?.message || 'Unknown Error.');
    };
    if (restoring)
        return React.createElement(Restore, { passphrase: passphrase, callback: callback });
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex justify-end cursor-pointer mr-7", onClick: () => dispatch(updateState('ready')) },
            React.createElement(Cross, { className: "w-4 h-4 fill-gray-600" })),
        React.createElement("div", { className: "h-screen w-screen flex flex-col justify-center items-center" },
            React.createElement("div", { className: "h-1/2 flex flex-col justify-evenly" },
                React.createElement("h3", null, t('restore_header')),
                React.createElement("form", { onSubmit: () => setRestoring(true) },
                    React.createElement("div", { className: "login-item" },
                        React.createElement("label", { htmlFor: "password", className: "text-sm text-gray-600" }, t('restore_text')),
                        React.createElement("input", { value: passphrase, name: "password", onChange: (e) => setPassphrase(e.target.value), type: "password", placeholder: "*********", className: `login-input ${error && 'border-red-500'}`, disabled: clicked })),
                    React.createElement("br", null),
                    error.length > 0 && (React.createElement("div", { className: "mb-4 text-red-500" }, error)),
                    React.createElement("br", null),
                    React.createElement("div", { className: "flex justify-end" },
                        React.createElement("input", { className: "mapp-button mapp-button-primary", type: "submit", value: `${t('restore')}`, disabled: clicked })),
                    React.createElement("div", { className: "mt-8" },
                        React.createElement("span", { className: "text-xs" }, t('restore_skip')),
                        React.createElement("a", { className: "text-xs ml-2", onClick: () => {
                                dispatch(updateState('ready'));
                            } }, t('skip')))),
                React.createElement("br", null),
                React.createElement("br", null)))));
};
export default BackupRestore;
