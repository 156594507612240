import * as React from "react";
import { useAppSelector } from "../../hooks";
import { selectConfiguration } from "../../reducer/app";
import sdk from "../../libs/mapp/sdk";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const FirstLoginActivation = () => {
    const configuration = useAppSelector(selectConfiguration);
    const user = useAppSelector((state) => state.user);
    const { t } = useTranslation();
    const [password, setPassword] = React.useState("");
    const [activationSend, setActivationSend] = React.useState(false);
    const [passwordError, setPasswordError] = React.useState(false);
    const navigate = useNavigate();
    const sendUserRegistration = async (password) => {
        if (process.env.LOGIN_SSO === "true") {
            await sdk.User.registerSSOChat(user.name);
        }
        else if (password) {
            try {
                await sdk.User.registerChat(password);
            }
            catch (e) {
                setPasswordError(true);
            }
        }
        setActivationSend(true);
    };
    return (React.createElement("div", { className: "app__right" },
        React.createElement("div", { className: "app__topbar" }),
        React.createElement("div", { className: "flex flex-col items-center justify-center h-1/2 p-4" },
            React.createElement("div", { className: "flex flex-col w-1/2" },
                React.createElement("span", { className: "mb-6 font-bold" }, activationSend ? t("chatActivated") : t("chat_first_time")),
                !activationSend && !configuration.modules.includes("keycloak") && (React.createElement(React.Fragment, null,
                    React.createElement("label", { htmlFor: "activate-password" }, t("login_form_placeholder_password")),
                    React.createElement("input", { id: "activate-password", type: "password", className: `mb-2 login-input ${passwordError && "border-red-400"}`, placeholder: "****", onChange: (e) => {
                            setPassword(e.target.value);
                            if (passwordError)
                                setPasswordError(false);
                        } }),
                    passwordError && (React.createElement("div", { className: "login-item flex items-center justify-center text-red-400" },
                        React.createElement("span", null, t("restore_error"))))))),
            !activationSend && (React.createElement("div", null,
                React.createElement("button", { className: `mapp-button mapp-button-primary`, onClick: () => sendUserRegistration(password), disabled: !password || passwordError }, t("activate")))),
            activationSend && (React.createElement("div", null,
                React.createElement("button", { className: "mapp-button mapp-button-primary", onClick: () => navigate('/login') }, t('toLogin')))))));
};
export default FirstLoginActivation;
