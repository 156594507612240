import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideActiveModal, selectImageModal } from '../../reducer/app';
import Cross from '../Icons/Cross';
import ModalSkelletonImages from '../ModalSkelleton/Images';
const ImageModal = () => {
    const dispatch = useAppDispatch();
    const appImageModal = useAppSelector(selectImageModal);
    if (!appImageModal?.url)
        return React.createElement(React.Fragment, null);
    return (React.createElement(ModalSkelletonImages, null,
        React.createElement("div", { className: "flex flex-col justify-between" },
            React.createElement("div", { className: "flex items-center justify-end mb-6" },
                React.createElement("div", { className: "flex justify-end cursor-pointer", onClick: () => dispatch(hideActiveModal()) },
                    React.createElement(Cross, { className: "w-4 h-4 fill-white" }))),
            React.createElement("img", { className: "max-h-[60rem] object-contain", src: appImageModal?.url }))));
};
export default ImageModal;
