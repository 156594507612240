import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getPublicRooms, getUsersAsync, selectPublicRooms, selectUsers, } from '../../reducer/matrix';
import { useTranslation } from 'react-i18next';
import RoomUserListItem from '../RoomUserListItem/RoomUserListItem';
import ReactPaginate from 'react-paginate';
const ChatUserRoomTable = ({ selected, setSelected }) => {
    const dispatch = useAppDispatch();
    const users = useAppSelector(selectUsers);
    const publicRooms = useAppSelector(selectPublicRooms);
    const userId = selected && "userId" in selected ? selected?.userId : undefined;
    const roomId = selected && "roomId" in selected ? selected?.userId : selected?.room_id;
    const { t } = useTranslation();
    const currentPage = 0;
    const [searchPattern, setSearchPattern] = React.useState('');
    const [category, setCategory] = React.useState('user');
    const userListRef = React.useRef(null);
    React.useEffect(() => {
        dispatch(getUsersAsync());
        dispatch(getPublicRooms());
    }, []);
    let searchedRooms = publicRooms.filter((r) => r.name.toLowerCase().includes(searchPattern?.toLowerCase() || ''));
    let userPagination = users.pagination;
    const searchFn = (searchTerm) => {
        if (category === 'user') {
            dispatch(getUsersAsync({ searchTerm }));
        }
    };
    const loadMoreUser = (e) => {
        dispatch(getUsersAsync({ page: e.selected + 1 }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "w-full flex items-center" },
            React.createElement("div", { className: `${category === 'user'
                    ? ' border-b-primary-full text-primary-full'
                    : ' border-b-transparent'} border-b-2 p-2 mr-1 cursor-pointer`, onClick: () => setCategory('user') }, t('people')),
            React.createElement("div", { className: `${category === 'rooms'
                    ? 'border-b-primary-full text-primary-full'
                    : 'border-b-transparent'}
                border-b-2 p-2 mr-1 cursor-pointer`, onClick: () => setCategory('rooms') }, t('groups'))),
        React.createElement("div", { className: "my-2 w-1/3" },
            React.createElement("div", { className: "input-search--wrapper border" },
                React.createElement("div", { id: "search", className: "input-search-button" }),
                React.createElement("input", { value: searchPattern, className: "input-search--input", onChange: (evt) => {
                        setSearchPattern(evt.target.value);
                        searchFn(evt.target.value);
                    }, type: "text", placeholder: `${t('search')}` }))),
        React.createElement("div", { className: "max-h-96 min-h-[24rem] overflow-y-scroll my-4", ref: userListRef },
            category === 'user' &&
                users &&
                users.items.map((user) => {
                    return (React.createElement(RoomUserListItem, { item: user, id: user.userId, imgUrl: user.imageUrl, name: user.name, selectAction: setSelected, selectedId: userId }));
                }),
            category === 'rooms' && (React.createElement(React.Fragment, null, searchedRooms.map((room) => {
                return (React.createElement(RoomUserListItem, { item: room, id: room.room_id, imgUrl: room.avatar_url || '', name: room.name || '', selectAction: setSelected, selectedId: roomId }));
            })))),
        React.createElement("div", { className: "h-5" }, category === 'user' && userPagination.total_pages > 1 && (React.createElement(ReactPaginate, { onPageChange: loadMoreUser, className: "post-pagination", breakLabel: "...", nextLabel: ">", marginPagesDisplayed: 3, pageRangeDisplayed: 3, pageCount: userPagination.total_pages, previousLabel: "<", forcePage: currentPage })))));
};
export default ChatUserRoomTable;
