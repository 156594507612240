import * as React from 'react';
import mcli from '../../libs/matrix';
import { getBackup, restoreBackupWithPassphrase, } from '../../libs/matrix/backup';
import { getDecryptedImageUrl } from '../../libs/matrix/message';
import { useTranslation } from 'react-i18next';
import loadingAnimation from '../../assets/animations/loading.json';
import { useLottie } from 'lottie-react';
import { LogoutButton } from '../Logout/LogoutButton';
const Restore = ({ passphrase, callback }) => {
    const animationOptions = {
        animationData: loadingAnimation,
        loop: true,
    };
    const { View: LottieView } = useLottie(animationOptions);
    const { t } = useTranslation();
    React.useEffect(() => {
        restoreBackupWithPassphrase(passphrase)
            .then(() => {
            getBackup().then((bk) => {
                if (bk?.backupInfo)
                    mcli.enableKeyBackup(bk.backupInfo);
            });
            recurseRoomDecrypt()
                .catch(console.warn)
                .finally(() => {
                callback(undefined, true);
            });
        })
            .catch((error) => {
            callback(error, false);
            console.error('Error restoring backup. ', error);
        });
    }, []);
    const decryptRoom = (room) => {
        if (room.getMyMembership() !== 'join')
            return Promise.reject();
        return new Promise((resolve) => {
            const events = room.getLiveTimeline().getEvents();
            let count = 0;
            events.forEach((evt) => {
                if (evt.isEncrypted()) {
                    mcli.decryptEventIfNeeded(evt).finally(() => {
                        const file = evt.getContent()?.file;
                        if (evt.getContent()?.msgtype === 'm.image' &&
                            file &&
                            file.url) {
                            getDecryptedImageUrl(file).finally(() => {
                                count += 1;
                                if (count >= events.length) {
                                    return resolve('');
                                }
                            });
                        }
                        else {
                            count += 1;
                            if (count >= events.length) {
                                return resolve('');
                            }
                        }
                    });
                }
                else {
                    count += 1;
                    if (count >= events.length) {
                        return resolve('');
                    }
                }
            });
        });
    };
    const recurseRoomDecrypt = (count = 0) => {
        const rooms = mcli.getRooms();
        return new Promise((resolve) => {
            rooms.forEach((room) => {
                decryptRoom(room).finally(() => {
                    count += 1;
                    if (count >= rooms.length) {
                        return resolve('');
                    }
                });
            });
        });
    };
    return (React.createElement("div", { className: "h-full w-full flex flex-col justify-center items-center" },
        React.createElement("div", { className: "w-1/3 flex flex-col justify-center items-center" },
            LottieView,
            React.createElement("div", null, t('restoring'))),
        React.createElement(LogoutButton, null)));
};
export default Restore;
