import mcli from ".";
import getSecretStorageKey from "./secretStorage";
let secretStorageKeys = {};
let secretStorageKeyInfo = {};
let secretStorageBeingAccessed = false;
let dehydrationCache = {};
export function encodeBase64(uint8Array) {
    return Buffer.from(uint8Array).toString("base64");
}
function isCachingAllowed() {
    return secretStorageBeingAccessed;
}
async function onSecretRequested(userId, deviceId, _requestId, secretName, deviceTrust) {
    const client = mcli;
    if (userId !== client.getUserId()) {
        return "";
    }
    if (!deviceTrust?.isVerified()) {
        console.error(`Ignoring secret request from untrusted device ${deviceId}`);
        return "";
    }
    if (secretName === "m.cross_signing.master" ||
        secretName === "m.cross_signing.self_signing" ||
        secretName === "m.cross_signing.user_signing") {
        const callbacks = client.getCrossSigningCacheCallbacks();
        if (!callbacks?.getCrossSigningKeyCache)
            return "";
        const keyId = secretName.replace("m.cross_signing.", "");
        const key = await callbacks.getCrossSigningKeyCache(keyId);
        if (!key) {
            console.error(`${keyId} requested by ${deviceId}, but not found in cache`);
        }
        return key ? encodeBase64(key) : "";
    }
    else if (secretName === "m.megolm_backup.v1") {
        const key = await client.crypto?.getSessionBackupPrivateKey();
        if (!key) {
            console.error(`session backup key requested by ${deviceId}, but not found in cache`);
        }
        return key ? encodeBase64(key) : "";
    }
    console.warn("onSecretRequested didn't recognise the secret named ", secretName);
    return "";
}
function cacheSecretStorageKey(keyId, keyInfo, key) {
    if (isCachingAllowed()) {
        secretStorageKeys[keyId] = key;
        secretStorageKeyInfo[keyId] = keyInfo;
    }
}
export async function getDehydrationKey(keyInfo, checkFunc) {
    const key = new Uint8Array();
    // need to copy the key because rehydration (unpickling) will clobber it
    dehydrationCache = { key: new Uint8Array(), keyInfo };
    return key;
}
export async function tryToUnlockSecretStorageWithDehydrationKey(client) {
    const key = dehydrationCache.key;
    let restoringBackup = false;
    if (key && (await client.getCrypto()?.isSecretStorageReady())) {
        console.warn("Trying to set up cross-signing using dehydration key");
        secretStorageBeingAccessed = true;
        try {
            // we also need to set a new dehydrated device to replace the
            // device we rehydrated
            let dehydrationKeyInfo = {};
            if (dehydrationCache.keyInfo && dehydrationCache.keyInfo.passphrase) {
                dehydrationKeyInfo = { passphrase: dehydrationCache.keyInfo.passphrase };
            }
            await client.setDehydrationKey(key, dehydrationKeyInfo, "Backup device");
            // and restore from backup
            const backupInfo = await client.getKeyBackupVersion();
            if (backupInfo) {
                restoringBackup = true;
                // don't await, because this can take a long time
                client.restoreKeyBackupWithSecretStorage(backupInfo).finally(() => {
                    secretStorageBeingAccessed = false;
                    if (!isCachingAllowed()) {
                        secretStorageKeys = {};
                        secretStorageKeyInfo = {};
                    }
                });
            }
        }
        finally {
            dehydrationCache = {};
            // the secret storage cache is needed for restoring from backup, so
            // don't clear it yet if we're restoring from backup
            if (!restoringBackup) {
                secretStorageBeingAccessed = false;
                if (!isCachingAllowed()) {
                    secretStorageKeys = {};
                    secretStorageKeyInfo = {};
                }
            }
        }
    }
}
export const crossSigningCallbacks = {
    getSecretStorageKey,
    cacheSecretStorageKey,
    onSecretRequested,
    getDehydrationKey,
};
