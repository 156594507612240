import * as React from 'react';
import Image from './Image';
import Text from './Text';
import Video from './Video';
import File from './File';
import BadEncryptText from './BadEncryptText';
import { useTranslation } from 'react-i18next';
import Audio from './Audio';
const Switch = ({ content, message, roomId, me, isReply, replyTo, callback, }) => {
    const { t } = useTranslation();
    switch (content.msgtype) {
        case 'm.image':
            return (React.createElement("div", { className: "" }, content?.file ? (React.createElement(Image, { name: content.body, file: content.file, callback: callback, info: content.info })) : (React.createElement(Image, { name: content.body, url: content.url || '', callback: callback, info: content.info }))));
        case 'm.video':
            return (React.createElement("div", { className: "" },
                React.createElement(Video, { content: content, callback: callback })));
        case 'm.file':
            return (React.createElement("div", { className: "" }, content?.file ? (React.createElement(File, { name: content.body, file: content.file })) : (React.createElement(File, { name: content.body, url: content.url || '' }))));
        case 'm.audio':
            return React.createElement(Audio, { content: content, callback: callback });
        case 'm.bad.encrypted':
            return (React.createElement(BadEncryptText, { me: me, isReply: isReply || false },
                React.createElement("span", null,
                    "\u201E",
                    t('bad_encrypt_message'),
                    "\u201D")));
        default:
            return (React.createElement(Text, { message: content.body || message, isForwarded: content['a.forwarded'] || false, me: me, replyTo: replyTo, isReply: isReply || false, roomId: roomId, callback: callback }));
    }
};
export default Switch;
