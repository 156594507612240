import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks';
import mcli from '../../libs/matrix';
import { hideActiveModal, selectChatMessageDeleteModal } from '../../reducer/app';
import ModalSkelleton from '../ModalSkelleton';
const ChatMessageDeleteModal = () => {
    const dispatch = useAppDispatch();
    const app = useAppSelector(selectChatMessageDeleteModal);
    const { t } = useTranslation();
    const deleteMessage = async (eventId, roomId) => {
        await mcli.redactEvent(roomId, eventId, undefined, {});
    };
    if (!app?.eventId || !app?.roomId)
        return React.createElement(React.Fragment, null);
    return (React.createElement(ModalSkelleton, null,
        React.createElement("div", { className: "p-4" },
            React.createElement("div", { className: "text-lg font-semibold" }, t('delete_message')),
            React.createElement("div", { className: "flex items-center justify-between mt-4" },
                React.createElement("button", { className: "mapp-button mapp-button-secondary", onClick: () => dispatch(hideActiveModal()) }, t('abort')),
                React.createElement("button", { className: "mapp-button mapp-button-primary", onClick: () => {
                        deleteMessage(app.eventId, app.roomId);
                        dispatch(hideActiveModal());
                    } }, t('accept'))))));
};
export default ChatMessageDeleteModal;
