import * as olm from '@matrix-org/olm';
import React from 'react';
import ReactDOM from 'react-dom/client';
// @ts-ignore
global.Olm = olm;
import './index.scss';
import './libs/mapp/sdk/index'; // load sdk at the beginning.
import './libs/matrix/index'; // load sdk at the beginning.
import { Provider } from 'react-redux';
import { store } from './store';
import ErrorBoundary from './components/ErrorPage';
import AlertSyncState from './components/Alert/AlertSyncState';
import Router from './Routes';
const root = ReactDOM.createRoot(document.getElementById('root'));
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
        .then((registration) => {
        registration.unregister();
    })
        .catch((error) => {
        console.error(error.message);
    });
}
root.render(React.createElement(Provider, { store: store },
    React.createElement(ErrorBoundary, null,
        React.createElement(AlertSyncState, null),
        React.createElement(Router, null))));
