import * as React from 'react';
import { Outlet, Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom';
import Login from './Login';
import StafficeAuthProvider from '../components/AuthProvider/StafficeAuthProvider';
import AppState from '../app';
import LoginSucess from './LoginSuccess';
import FirstLogin from './FirstLogin';
import AppLayout from '../layouts/AppLayout';
const Router = () => {
    const routes = createBrowserRouter(createRoutesFromElements(React.createElement(React.Fragment, null,
        React.createElement(Route, { path: "/", element: React.createElement(StafficeAuthProvider, null,
                React.createElement(Outlet, null)) },
            React.createElement(Route, { path: "/chat", element: React.createElement(AppState, null) })),
        React.createElement(Route, { path: "login", element: React.createElement(Login, null) }),
        React.createElement(Route, { path: "login-success", element: React.createElement(LoginSucess, null) }),
        process.env.FEATURE_FIRSTLOGIN && (React.createElement(Route, { path: "first-login", element: React.createElement(AppLayout, null,
                React.createElement(FirstLogin, null)) })),
        React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/chat", replace: true }) }))));
    return React.createElement(RouterProvider, { router: routes });
};
export default Router;
