import * as React from 'react';
import mcli from '../../libs/matrix';
import { downloadFile } from '../../libs/common/download';
import { getDecryptedImageUrl, } from '../../libs/matrix/message';
import { useAppDispatch } from '../../hooks';
import { openImageModal } from '../../reducer/app';
const imageStore = [];
const Image = React.memo(({ url, file, name, callback, info }) => {
    const dispatch = useAppDispatch();
    const isFile = !!file?.url;
    const found = imageStore.find((i) => i.url === (isFile ? file.url : url));
    const imageUrl = !isFile
        ? mcli.mxcUrlToHttp(url || '') || undefined
        : undefined;
    const [image, setImage] = React.useState(found?.objUrl || imageUrl);
    const [ready, setReady] = React.useState(false);
    React.useEffect(() => {
        if (!found && !image && isFile) {
            getDecryptedImageUrl(file).then((resUrl) => {
                if (resUrl) {
                    setImage(resUrl);
                    imageStore.push({
                        url: file.url,
                        objUrl: resUrl || '',
                    });
                }
            });
        }
        else {
        }
    }, []);
    const calcHeight = (originWidth, width, originHeight) => {
        let factor = 1;
        if (originWidth < width) {
            factor = originWidth / width;
        }
        if (originWidth > width) {
            factor = width / originWidth;
        }
        return originHeight * factor;
    };
    const width = info?.w ? (info.w <= 320 ? info.w : 320) : 320;
    const height = calcHeight(info?.w || 250, width, info?.h || 300);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "h-full w-full" }, image && (React.createElement(React.Fragment, null,
            !ready && (React.createElement("div", { className: `max-w-full image-skeleton`, style: { width, height } })),
            React.createElement("img", { onLoad: () => {
                    setReady(true);
                    callback();
                }, src: image, onClick: () => dispatch(openImageModal({ url: image })), className: `rounded ${!ready && 'hidden'}`, "data-type": "modal-btn", style: { width, height } }),
            React.createElement("a", { className: "chat-message-download", onClick: () => downloadFile(image || '', name) }, "download"))))));
});
export default Image;
